import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import "./SignUp.css";


const SignUp: React.FC = () => {

  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector(
    ({auth}) => auth
  );

  useEffect(() => {
    document.title = "Sign up";
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  });
  return (
    <>
      <div className="SignIn_container ">
        <div className="SignIn_content pt-16 flex justify-center content-center md:block mb-6">
          <Box sx={{ maxWidth: 300, margin: "auto" }}>
          <Link to="/">
            <Box className="flex flex-row gap-2 content-center mb-8 absolute md:static top-12 left-7">
              <img src="/assets/logo.png" alt="Logo" className="w-5" />
              <Typography
                color="#3D7EBE"
                fontSize={"13px"}
                fontWeight={700}
                paddingTop={"3px"}
              >
                ACE PACIFIC BANK
              </Typography>
            </Box>
            </Link>
            <div className="mt-12 md:mt-0"></div>
            <h1 className="md:text-4xl text-3xl md:tracking-wide tracking-wider font-medium">
              Create Account
            </h1>
            <p className="md:text-sm text-xs font-light text-[#3b4752] tracking-wider pt-2">
              For the purpose of industry regulation, your details are required.
            </p>
            <Outlet />
          </Box>
        </div>
        <div className="SignIn_img">
          <div>
            <img src="/assets/signin.png" alt="Sign In" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
