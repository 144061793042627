import React, {
  useState,
  ChangeEvent,
  FormEvent,
  useEffect,
  useCallback,
} from "react";
import { Box, Button, Typography } from "@mui/material";
import InputField from "../../components/FormInputField/InputField";
import { Link } from "react-router-dom";
import "./SignIn.css";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import { cleanUp, signInUser } from "../../Redux/authSlice";
import InputFieldPassword from "../../components/FormInputField/InputFieldPassword";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface FormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const [validationErrors, setValidationErrors] = useState<Partial<FormData>>(
    {}
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn, loading, message } = useAppSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    document.title = "Sign in";
    if (isLoggedIn) {

      navigate("/dashboard");
    }
  });

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });

      if (name === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(value);
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: isValidEmail ? "" : "Invalid email address",
        }));
      }
    },
    [formData]
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Validate password length
    if (formData.password.length < 6) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters long",
      }));
      //Validate email field
      if (formData.email.length < 1) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          email: "Email field cannot be empty",
        }));
      }
      return;
    }

    // Clear previous validation errors
    setValidationErrors({});
    dispatch(signInUser(formData));

    if (isLoggedIn) {
      dispatch(cleanUp());
      navigate("/dashboard")
    }
  };

  return (
    <>
      <div className="SignIn_container">
        <div className="SignIn_content pt-12 flex justify-center content-center md:block">
          <Box className="w-80 min-w-72 m-auto">
            <Link to="/">
              <Box className="flex flex-row gap-2 content-center mb-12  absolute md:static top-12 left-7">
                <img src="/assets/logo.png" alt="Logo" className="w-5" />
                <Typography
                  color="#3D7EBE"
                  fontSize={"13px"}
                  fontWeight={700}
                  paddingTop={"3px"}
                >
                  ACE PACIFIC BANK
                </Typography>
              </Box>
            </Link>
            <h1 className="md:text-4xl text-3xl md:tracking-wide tracking-wider font-medium">
              Welcome Back
            </h1>
            <p className="md:text-sm text-xs font-light text-[#3b4752] tracking-wider pt-2">
              You need to be signed in to access the project dashboard.
            </p>
            <form onSubmit={handleSubmit}>
              <Box className="formControl flex flex-col gap-3 mt-5">
                <InputField
                  type="email"
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  err={validationErrors.email}
                />
                <InputFieldPassword
                  type="password"
                  name="password"
                  label="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  err={validationErrors.password}
                />
              </Box>
              <Typography fontSize={"14px"} color="#1a8fe3" align="right">
                Forgot Password?
              </Typography>

              <Button
                type="submit"
                variant="contained"
                size="medium"
                fullWidth
                sx={{
                  mt: 2,
                }}
                disabled={loading}
              >
                {loading ? "Signing In..." : "Sign In"}
              </Button>

              <span className="text-sm text-center block pt-3 text-red-600">
                {message}
              </span>
            </form>

            <Typography
              fontSize={"14px"}
              color="#11161B"
              align="center"
              paddingTop={5}
            >
              Haven't joined yet?{" "}
              <Link to="/sign-up" className="text-[#11161b] underline">
                Sign up
              </Link>
            </Typography>
          </Box>
        </div>
        <div className="SignIn_img">
          <div>
            <img src="/assets/signin.png" alt="Sign In" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignIn;
