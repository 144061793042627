import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import "./Admin.css";
import AdminTransactionTable from "../../../components/TransactionTable/AdminTransactionTable";

const Admin = () => {
  useEffect(() => {
    document.title = "Admin";
  });
  return (
    <div>
      <div>
        <h2 className="font-bold text-2xl md:text-4xl pt-8 text-[#4583bf]">Admin Dashboard</h2>
      </div>
      <div className="admin_box mt-8">
        <Box sx={{ flexGrow: 2 }}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3 }}
            columns={{ xs: 2, sm: 8, md: 12 }}
            sx={{ alignContent: "center", justifyItems: "center" }}
            className="px-1 md:px-3"
          >
            <Grid item xs={6} sm={4} md={3}>
              <Box className="rounded h-32 bg-[#4583bf] px-4 pt-6">
                <h3 className="text-lg font-semibold text-[#fefefe]">Total Users :</h3>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Box className="rounded h-32 bg-red-100 px-4 pt-6">
                <h3 className="text-lg font-semibold text-[#433d3d]">Today's tranaction :</h3>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Box className="rounded h-32 bg-slate-200 px-4 pt-6">
                <h3 className="text-lg font-semibold text-[#1b1a1a]]">Streaks :</h3>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Box className="rounded h-32 bg-green-300 px-4 pt-6">
                <h3 className="text-lg font-semibold text-[#1b1a1a]]">Dommy text :</h3>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="bg-[#fefefe] my-8 px-5 py-5 rounded-lg">
        <AdminTransactionTable />
      </div>
    </div>
  );
};

export default Admin;
