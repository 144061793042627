import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../constants/API_BASE_URL";
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

interface User {
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  id: string;
  accountNumber: string;
  phoneNumber: string;
  profilePictureUrl: string;
  token: string;
  pin: string;
  userId: string;
  initial: string;
}

interface userData {
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  gender: string;
}

interface AuthState {
  user: User | null;
  isLoggedIn: boolean;
  loading: boolean;
  message: string;
}

export const signInUser = createAsyncThunk<
  any, // Adjust type based on actual data returned by API
  { email: string; password: string },
  { rejectValue: string } // Specify rejectValue type here
>(
  "auth/authSignIn",
  async (userData: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/User/Login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        
        if (!data.hasResult || !data.successful) {
          return rejectWithValue(data.message || "Login failed");
        }

        return data;
      } else {
        return rejectWithValue("Network response was not ok");
      }
    } catch (error: any) {
      toast.error(error.message || "Login failed");
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (userData: userData) => {
    const response = await fetch(
      `${API_BASE_URL}/api/User/RegisterUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify(userData),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { message: "signup failed" };
    }
  }
);

const loadUserFromLocalStorage = (): User | null => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

const saveUserToLocalStorage = (user: User | null) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("IsLoggedIn", "true");
  } else {
    localStorage.removeItem("user");
  }
};

const initialState: AuthState = {
  user: loadUserFromLocalStorage(),
  isLoggedIn: Boolean(loadUserFromLocalStorage()),
  loading: false,
  message: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.user = null;
      state.isLoggedIn = false;
      saveUserToLocalStorage(null);
    },
    cleanUp(state) {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        if (action.payload.successful) {
          state.user = action.payload.result;
          saveUserToLocalStorage(action.payload.result);
          if (state.user) {
            state.isLoggedIn = true;
          }
        } else {
          const { message } = action.payload;
          state.message = message;
          toast.error(message || "Login failed");
        }

        state.loading = false;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string') {
          state.message = action.payload;
          toast.error(action.payload);
        } else {
          state.message = "An unknown error occurred";
        }
      })
      .addCase(signUpUser.pending, (state) => {
        state.loading = true;
        console.log("pending... signing up user");
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.successful) {
          state.user = action.payload.result;
          saveUserToLocalStorage(action.payload.result);
          if (state.user) {
            state.isLoggedIn = true;
          }
          console.log("fulfilled");
        } else {
          const { message } = action.payload;
          state.message = message;
        }
        state.loading = false;
      })
      .addCase(signUpUser.rejected, (state) => {
        state.loading = false;
        state.message = "Oops, something went wrong. Try again later.";
        console.log("rejected");
      });
  },
});

export const { logout, cleanUp } = authSlice.actions;
export default authSlice.reducer;
