import React from "react";
import { FormControl } from "@mui/material";

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  err?: string;
  pattern?: number;
  placeholder?: string | "";
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  err,
  placeholder,
  pattern
}) => {
  return (
    <>
      <FormControl>
        <label htmlFor={name} className="text-sm">
          {label}
        </label>
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={pattern}
          placeholder={placeholder}
          className={`text-[#3B4752] w-full focus:shadow-xl border-[#cfd8e1] outline-0 border py-1.5 px-3 focus:border-[#3d7ebe] rounded ${
            err && value.length <= 0 ? "border-red-500" : ""
          }`}
        />

        {err === "Invalid email address" ? (
          <div className="text-red-500 pt-1 text-sm">{err}</div>
        ) : err && value.length <= 0 ? (
          <div className="text-red-500 pt-1 text-sm">{err}</div>
        ) : (
          ""
        )}
        
      </FormControl>
    </>
  );
};

export default InputField;
