import React, { useState, ChangeEvent, FormEvent } from "react";
import TransferHeader from "../TransferHeader";
import { Box, Button } from "@mui/material";
import TransboxHeader from "../TransboxHeader";
import InputField from "../../FormInputField/InputField";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../Redux/hooks";
import { getAmount } from "../../../Redux/transferSlice";

interface FormData {
  amount: string;
  transactionNarration: string;
}

const TransferAceAmount = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    amount: "",
    transactionNarration: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Partial<FormData>>(
    {}
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNarrationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(getAmount(formData));

    navigate("/dashboard/transfer/slide-three");
  };
  return (
    <div>
      <TransferHeader />
      <div className="py-10  flex items-center justify-center ">
        <Box
          sx={{
            width: 420,
            background: "#ffffff",
          }}
          className="px-6 py-8 border border-[#DADCDF]"
        >
          <TransboxHeader />
          <div>
            <form onSubmit={handleSubmit}>
              <Box className="formControl flex flex-col gap-3 mt-5 mb-2">
                <InputField
                  type="text"
                  name="amount"
                  label="Enter Amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  err={validationErrors.amount}
                />

                <InputField
                  type="text"
                  name="transactionNarration"
                  label="Narration"
                  value={formData.transactionNarration}
                  onChange={handleNarrationChange}
                  err={validationErrors.transactionNarration}
                />

                {/* <InputField
                  type="text"
                  name="recipientWalletAccountNumber"
                  label="Account Number"
                  value={formData.recipientWalletAccountNumber}
                  onChange={handleNarrationChange}
                  err={validationErrors.transactionNarration}
                /> */}

                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </form>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default TransferAceAmount;
