import React, { useState, ChangeEvent, FormEvent } from "react";
import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../../components/FormInputField/InputField";
import InputFieldNumber from "../../../components/FormInputField/InputFieldNumber";

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  userName: string
  phoneNumber: string;
}

const SignupInfo: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    userName: "",
    phoneNumber: "",

  });
  const [validationErrors, setValidationErrors] = useState<Partial<FormData>>(
    {}
  );

  const validateField = (fieldName: keyof FormData): string => {
    if (fieldName === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(formData.email) ? "" : "Invalid email address";
    }
    return formData[fieldName].trim()
      ? ""
      : `Please enter a valid ${fieldName}`;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as { name: keyof FormData; value: string };
    setFormData({ ...formData, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name),
    }));
  };

  const handleGenderChange = (selectedGender: string) => {
    setFormData({ ...formData, gender: selectedGender });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      gender: validateField("gender"),
    }));
  };

  const handleNumberInputChange = (value: string) => {
    setFormData({ ...formData, phoneNumber: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: validateField("phoneNumber"),
    }));
  };

  const validatePhoneNumber = (phoneNumber: string): boolean => {
    const phoneNumberPattern: RegExp = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fields: (keyof FormData)[] = [
      "email",
      "firstName",
      "lastName",
      "gender",
      "userName",
    ];

    const errors: Partial<FormData> = {};
    fields.forEach((field) => {
      errors[field] = validateField(field);
    });

    const hasErrors = Object.values(errors).some((error) => !!error);
    if (!hasErrors) {
      setValidationErrors(errors);
      localStorage.setItem("info", JSON.stringify(formData));
      navigate("/sign-up/set-password");
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box className="formControl flex flex-col gap-3 mt-5 mb-2">
          <InputField
            name="firstName"
            type="text"
            label="First name"
            value={formData.firstName}
            onChange={handleInputChange}
            err={validationErrors.firstName}
          />
          <InputField
            name="lastName"
            type="text"
            label="Last name"
            value={formData.lastName}
            onChange={handleInputChange}
            err={validationErrors.lastName}
          />
           <InputField
            name="userName"
            type="text"
            label="Username"
            value={formData.userName}
            onChange={handleInputChange}
            err={validationErrors.userName}
          />
          <FormControl
            size="small"
            error={!!validationErrors.gender && formData.gender.length <= 0}
          >
            <label id="gender" className="text-sm">
              Gender
            </label>
            <Select
              id="gender"
              value={formData.gender}
              onChange={(e) => handleGenderChange(e.target.value as string)}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="male">male</MenuItem>
              <MenuItem value="female">female</MenuItem>
            </Select>
            {validationErrors.gender && formData.gender.length <= 0 ? (
              <span className="text-sm pt-1 text-red-500">
                {validationErrors.gender}
              </span>
            ) : (
              ""
            )}
          </FormControl>
          <InputField
            name="email"
            type="email"
            label="Email Address"
            value={formData.email}
            onChange={handleInputChange}
            err={validationErrors.email}
          />
          {/* <InputFieldNumber
            onChange={handleNumberInputChange}
            value={formData.phoneNumber}
            valid={validatePhoneNumber(formData.phoneNumber)}
            err={validationErrors.phoneNumber}
          /> */}
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          sx={{ mt: 2 }}
        >
          Continue
        </Button>
      </form>
      <Typography fontSize="14px" color="#11161B" align="center" sx={{ py: 5 }}>
        Already own an account?{" "}
        <Link to="/sign-in" className="text-[#11161b] underline">
          Sign In
        </Link>
      </Typography>
    </div>
  );
};

export default SignupInfo;
