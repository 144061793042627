import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "./ContactUs.css";
import Navbar from "../../components/Landing/Navbar";
import NavbarMobile from "../../components/Landing/NavbarMobile";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  return (
    <>
      <div className="w-screen h-screen overflow-x-hidden">
        <div className="contact bg-[#f7f7f5]">
          <div className="contact__hero flex items-center text-white justify-center lg:justify-end relative">
            <Navbar />
            <div className="z-40">
              <NavbarMobile />
            </div>
            <div className="px-5 lg:pr-24 z-10 text-center lg:text-right">
              <h1 className="font-black text-5xl md:text-8xl pb-3">
                Contact Us
              </h1>
              <p className="text-2xl md:text-3xl md:pt-8 lg:pl-12">
                We're here to help you with any questions or concerns you may have.
              </p>
            </div>
            <div className="absolute hero-wave">
              <img
                src="/assets/landing/floating_image_04.png"
                className="w-full"
                alt="wave"
              />
            </div>
          </div>
          <div className="contact__details pt-10 pb-14 mt-11 relative px-6 flex flex-col items-center md:items-start">
            <div className="w-full md:w-3/4 lg:w-1/2 z-10">
              <Typography variant="h4" className="font-black text-4xl md:text-5xl pb-3 text-[#181818]">
                Get In Touch
              </Typography>
              <Typography variant="body1" className="text-lg md:text-2xl md:pt-8 mb-8 text-[#181818]">
                Phone Number: +49 62 917-30775
              </Typography>
              <Typography variant="body1" className="text-lg md:text-2xl mb-8 text-[#181818]">
                Email: Contact@acepacificdigital.com
              </Typography>
              <Typography variant="body1" className="text-lg md:text-2xl mb-8 text-[#181818]">
                Head Office: Taunusanlage 18, 60326 Frankfurt am Main, Germany
              </Typography>
              <Link to="/">
                <Button variant="outlined" className="rounded-full">
                  Home
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-[#191919] text-white w-full mt-12 py-6 justify-around text-center md:flex">
          <p>©2023 Ace Pacific Bank. All rights reserved</p>
          <ul className="flex gap-6 text-xs md:text-sm items-center justify-center mt-3">
            <li>
              <a href="/">Privacy Policy</a>
            </li>
            <li>
              <a href="/">Cookie Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
