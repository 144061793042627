//const API_BASE_URL = "https://cindicateo-001-site1.ftempurl.com";
//const API_BASE_URL = "https://localhost:44346";
const API_BASE_URL = "https://acepacific.runasp.net";

const SECRET_KEY = "22&3gbsjj829"

export const DEFAULT_WITHIN_BANK_VALUE = true;


function getTimeOfDay() {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }
  function formatNumberWithCommas(numberString: string): string {
    const number = parseFloat(numberString);
    return number.toLocaleString('en-US');
  }

  const formatDateObject = (dateString: string) => {
  
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
  
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    let amOrPm = 'AM';
  
    if (hours >= 12) {
      amOrPm = 'PM';
      hours = hours % 12 || 12;
    }
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
  
    return `${month} ${day}, ${year} ${formattedTime}`;
  }
  


//const API_BASE_URL = `http://localhost:3000`;

export { API_BASE_URL, SECRET_KEY, getTimeOfDay, formatDateObject, formatNumberWithCommas };