import { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { PiPaperPlaneTiltLight } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import { LiaTimesSolid } from "react-icons/lia";
import { TbUserHexagon } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { IoReceiptOutline } from "react-icons/io5";
import { LuSettings } from "react-icons/lu";
import { LuUserCircle2 } from "react-icons/lu";
import { BsPatchQuestion } from "react-icons/bs";
import { RiLogoutCircleRLine } from "react-icons/ri";

import { NavLink, Link, useLocation } from "react-router-dom";
import "./DashMobileSidebar.css";
import LogoutModal from "../../Modals/LogoutModal";

const DashMobileSidebar = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false)
  const prevPathname = useRef<string>("");
  const MenuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <RxDashboard size={20} />,
    },
    {
      path: "/dashboard/transfer",
      name: "Transfer",
      icon: <PiPaperPlaneTiltLight size={20} />,
    },
    {
      path: "/dashboard/bill-payment",
      name: "Bill payment",
      icon: <TbUserHexagon size={20} />,
    },
    {
      path: "/dashboard/airtime-data",
      name: "Airtime and Data",
      icon: <HiOutlineDevicePhoneMobile size={20} />,
    },
    {
      path: "/dashboard/transactions",
      name: "Transactions",
      icon: <IoReceiptOutline size={20} />,
    },
  ];
  const ManageItem = [
    {
      path: "/dashboard/profile",
      name: "Profile",
      icon: <LuUserCircle2 size={20} />,
    },
    // {
    //   path: "/dashboard/settings",
    //   name: "Settings",
    //   icon: <LuSettings size={20} />,
    // },
  ];
  const [toggle, setToggle] = useState<boolean>(false);
  useEffect(() => {
    if (location.pathname !== prevPathname.current) {
      setToggle(true);
    }
  }, [location]);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handleLogout = () => {
    setShowModal(!showModal)
  }

  return (
    <div>
      <div
        className={`dashmobilesidebar_container ${toggle ? "" : "display"} `}
      >
        <Box className="dashmobilesidebar_burger" onClick={handleToggle}>
          <button
            onClick={handleToggle}
            className={`box ${toggle ? "open" : "close"}`}
          >
            <RxHamburgerMenu size={25} />
          </button>
          <button className={`times ${toggle ? "close" : "open"}`}>
            <LiaTimesSolid size={25} />
          </button>
        </Box>
        <div className="dashmobilesidebar_menu">
          <Box className="flex flex-row gap-2 content-center pb-8  ">
            <img src="/assets/logo.png" alt="Logo" className="w-5" />
            <Link to="/">
            <Typography color="#3D7EBE" fontSize={"13px"} fontWeight={700}>
              ACE PACIFIC BANK
            </Typography>
            </Link>
          </Box>
          <div className="sidebar_links">
            <div>
              <Box className="pb-10">
                <h3 className="text-sm text-[#628ddd] pb-4">Overview</h3>
                <ul className="flex flex-col gap-4">
                  {MenuItems.map((menuItems, index) => (
                    <li key={index}>
                      <NavLink
                        to={menuItems.path}
                        className={({
                          isActive,
                        }) => `flex content-center justify-start gap-2  text-[#628ddd] 
                     ${isActive ? "text-[#42a1ff] font-semibold" : ""}`}
                      >
                        {menuItems.icon}
                        {menuItems.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Box>
              <Box className="pb-16">
                <h3 className="text-sm text-[#628ddd] pb-4">Manage</h3>
                <ul className="flex flex-col gap-4">
                  {ManageItem.map((manageItems, index) => (
                    <li key={index}>
                      <NavLink
                        to={manageItems.path}
                        className={({
                          isActive,
                        }) => `flex content-center justify-start gap-2 text-[#628ddd] 
                     ${isActive ? "text-[#42a1ff] font-semibold" : ""}`}
                      >
                        {manageItems.icon}
                        {manageItems.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Box>
            </div>
            <Box>
              <ul className="flex flex-col gap-4">
                {/* <li>
                  <Link
                    to={"/support"}
                    className="flex content-center justify-start gap-2 text-[#628ddd] "
                  >
                    <BsPatchQuestion size={20} />
                    Support
                  </Link>
                </li> */}
                <li className="flex content-center justify-start gap-2 text-[#628ddd] cursor-pointer" onClick={handleLogout}>
                  <RiLogoutCircleRLine size={20} />
                  Logout
                </li>
              </ul>
              <LogoutModal enable={showModal} setIsSubmitting={setShowModal} />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashMobileSidebar;
