import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="bg-transparent z-20 hidden  absolute px-12 top-10 w-full md:flex justify-between">
      <Box className="flex flex-row gap-2 content-center pb-8 ">
        <img src="/assets/logo.png" alt="Logo" className="w-5 h-5" />
        <Typography color="#fff" fontSize={"15px"} fontWeight={700}>
          <Link to="/">
            ACE PACIFIC BANK
          </Link>
        </Typography>
      </Box>

      <div className="nav-link">
        <ul className="flex gap-3">
          <li>Home</li>
          <Link to="/about-us">
            <li>About</li>
          </Link>
          <Link to="/contact-us">
          <li>Contact</li>
          </Link>
          <li>
            <Link to="/sign-in">
              Online Banking
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
