import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import "./Dashboard.css";
import DashSidebar from "../../components/Dashboard/DashSidebar/DashSidebar";
import { Divider } from "@mui/material";
import DashMobileSidebar from "../../components/Dashboard/DashMobileSidebar/DashMobileSidebar";
import DashboardHeader from "../../components/Dashboard/DashboardHeader/DashboardHeader";
import { useAppSelector } from "../../Redux/hooks";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector(({auth}) => auth);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <div className="Dashboard_container flex bg-[#fefefe]">
        <DashSidebar />
        <DashMobileSidebar />
        <Divider orientation="vertical" flexItem />
        <div className="w-full px-2 md:px-12 pt-8">
          <DashboardHeader />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
