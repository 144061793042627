import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./Transfer.css"

const Transfer = () => {
  useEffect(() => {
    document.title = "Transfer";
  });
  return (
    <div className="pt-6 h-5/6 relative transfer_container">
      <Outlet />
    </div>
  );
};

export default Transfer;

