import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { LiaTimesSolid } from "react-icons/lia";
import AuthCode from "react-auth-code-input";
import "./modal.css";
import { useContext, useEffect, useState } from "react";
import { API_BASE_URL } from "../../constants/API_BASE_URL";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";
import useTransferSelector from "../Transfer/useTransferSelector";

interface ModalProps {
  enable: boolean;
  setIsModalOpen: (value: boolean) => void;
  setSuccess: (value: boolean) => void;
  userId: string;
  recipientWalletAccountNumber:string
  transferDetails?: {
    amount: number;
    accountNumber: string;
    recipientWalletAccountNumber : string;
    accountName: string;
    withinBank: boolean;
    transactionNarration: string;
  };
}

const ConfirmPin: React.FC<ModalProps> = ({
  enable,
  setIsModalOpen,
  setSuccess,
  userId,
  
  transferDetails
}) => {
  const { recipientWalletAccountNumber} = useTransferSelector();
  const [result, setResult] = useState<string>();
  const [message, setmessage] = useState<string>();
  const navigate = useNavigate();

  const { user } = useAppSelector(({ auth }) => auth);
  
  const handleOnChange = async (res: any) => {
    
    setResult(res);
    setmessage("");
  };


  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/Wallet/ValidatePin`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        }),
        body: JSON.stringify({
          pin: result,
          userId: user?.id,
        }),
      });
  
      if (response.ok) {
        const data = await response.json(); // Parse response for validation
        console.log(data)
        if (data.successful) { 
          const transferResponse = await fetch(`${API_BASE_URL}/api/Wallet/MakeIntraTransfer`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "69420",
            }),
            body: JSON.stringify({
              amount: transferDetails?.amount,
              recipientWalletAccountNumber: transferDetails?.accountNumber,
              senderWalletAccountNumber: user?.accountNumber,
              transactionNarration: transferDetails?.transactionNarration,
              transactionPin: result,
            }),
          });

          console.log("transferResponse: " + transferResponse);
          if (transferResponse.ok) {
            // Handle transfer success
            console.log("Transfer successful");
            setIsModalOpen(false);
          setSuccess(true); // Set success to true when the modal is closed
          navigate("/Modals/SuccessfulTransfer", { replace: true });
          } else {
            // Handle transfer failure
            console.error("Transfer failed");
          }
        } else {
          // Handle validation failure based on data.message (if available)
          console.error("Validation failed:", data.message);
        }
      } else {
        // Handle initial API call failure
        console.error("Validation API failed");
      }
    } catch (error) {
      // Handle general errors
      console.error("Error:", error);
    }
  };

  return (
    <div className="confirmPin">
      <Modal
        open={enable}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="box_modal log rounded px-3">
          <div>
            <div
              className="modal_close flex items-center justify-center cursor-pointer"
              onClick={() => setIsModalOpen(!enable)}
            >
              <LiaTimesSolid size={25} />
            </div>
          </div>
          <h2 id="modal-modal-title" className="font-bold text-lg leading-3">
            Enter Transaction PIN
          </h2>
          <span style={{ fontSize: "10px" }} className="text-[#7D8398]">
            To complete this transfer, please enter your 4-Digit Transaction PIN
          </span>

          <div className="pt-5 pb-2 flex justify-center gap-2 ">
            <AuthCode
              inputClassName="w-16 h-16 border mx-1 rounded bg-transparent
                          outline-none text-center font-semibold text-xl
                          spin-button-none border-gray-400
                           focus:text-gray-700 text-black
                          transition spin-button-none"
              onChange={handleOnChange}
              allowedCharacters="numeric"
              length={4}
            />
          </div>
          <Button
            variant="contained"
            size="medium"
            fullWidth
            sx={{
              mt: 2,
            }}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <span className="text-sm text-center block pt-3 text-red-600">
            {message}
          </span>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmPin;

