import { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { HiOutlineDevicePhoneMobile, HiMiniWifi, HiMiniCreditCard, HiOutlineCreditCard } from "react-icons/hi2";
import { MdOutlineSportsSoccer } from "react-icons/md";
import { PiTelevisionSimpleLight } from "react-icons/pi";
import { TfiPlug } from "react-icons/tfi";
import { Grid, Box } from "@mui/material";
import { useAppSelector } from "../../Redux/hooks";
import "./DashboardHome.css";
import TransactionTable from "../../components/TransactionTable/TransactionTable";
import { API_BASE_URL, getTimeOfDay } from "../../constants/API_BASE_URL";
import { numberWithCommas } from "../../Helpers/NumberWithCommas";

const DashboardHome = () => {
  const [accountBalance, setAccountBalance] = useState(0.0);
  const [accountNumber, setAccountNumber] = useState();
  const { user } = useAppSelector(({ auth }) => auth);
  const [timeOfDay, setTimeOfDay] = useState(getTimeOfDay());

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      document.title = "Dashboard";

      try {
        const endPoint = `${API_BASE_URL}/api/Wallet/GetUserAccountAdmin/${user?.accountNumber}`;
        console.log(endPoint);
        const options = {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "69420",
          }),
        };

        const response = await fetch(endPoint, options);
        console.log(response);
        if (response.ok) {
          const data = await response.json();
          console.log(response, 'response');
          console.log(data)
          if (data.successful) {
            setAccountBalance(data.result.accountBalance);
            setAccountNumber(data.result.accountNumber);
          } else {
            setAccountBalance(0.0);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
    setTimeOfDay(getTimeOfDay());
  }, [user]); // Include user in the dependency array if it's used inside the effect

  const options = [
    {
      path: "/dashboard/transfer",
      name: "Transfer",
    },
  ];
  const pinLink  = user?.pin ?({

      name: "Reset Pin",
      path: "/dashboard",
      icons: <HiMiniCreditCard size={20} />,
      bg: "#e8ffe4",
  }
  ) : (
    {
      name: "Create Pin",
      path: "/dashboard",
      icons: <HiOutlineCreditCard size={20} />,
      bg: "#e8ffe4",
    }
  );
  const QuickLinks = [
    pinLink,
    {
      name: "Airtime",
      path: "/dashboard",
      icons: <HiOutlineDevicePhoneMobile size={20} />,
      bg: "#e8ffe4",
    },
    {
      name: "Betting",
      path: "/dashboard",
      icons: <MdOutlineSportsSoccer size={20} />,
      bg: "#FFFCE0",
    },
    {
      name: "Cable TV",
      path: "/dashboard",
      icons: <PiTelevisionSimpleLight size={20} />,
      bg: "#FFEAEA",
    },
    {
      name: "Data",
      path: "/dashboard",
      icons: <HiMiniWifi size={20} />,
      bg: "#DCFFFF",
    },
    {
      name: "Electricity",
      path: "/dashboard",
      icons: <TfiPlug size={20} />,
      bg: "#5768fd24",
    },
  ];

  return (
    <Box className="w-full DashboardHome_container pt-8 px-2 mt-2">
      <Box sx={{ flexGrow: 2 }}>
        <Grid
          container
          spacing={{ xs: 2, sm: 5 }}
          columns={{ xs: 2, sm: 2, md: 12 }} 
          className="px-1 md:px-5"
        >
          <Grid item xs={12} sm={8}>
            <Box className="box box_one px-4 md:px-6 py-4 md:py-6 ">
              <p className="text-white text-xs md:text-sm">
              {user?.firstName}, here is your balance:
              </p>
              <p className="text-white text-2xl  md:text-3xl pb-1 md:pb-3  font-bold">
                ${numberWithCommas(accountBalance)}.00
              </p>
              <p className="text-white text-1xl  md:text-1xl pb-1 md:pb-3">
                Account Number {accountNumber}
              </p>
              <div className="flex items-center gap-2 md:gap-6 ">
                {options.map((option, index) => (
                  <a
                    className="bg-[#0038D9] text-xs md:text text-white rounded aa-a "
                    href={option.path}
                    key={index}
                  >
                    {option.name}
                    <FiArrowRight />
                  </a>
                ))}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className="box box_two px-6 py-4 flex gap-4">
              <div>
                <h2 className="pb-2 text-white font-bold text-xl tracking-wider">
                  Mobile App <br /> Coming Soon
                </h2>
                <span className="text-white font-normal ">Available On</span>
                <div className="flex items-center gap-2 mt-2">
                  <button className="box_two-btn">
                    <img src="/assets/googlelogo.png" alt="logo" />
                    <img src="/assets/google.png" alt="logo" />
                  </button>
                  <button className="box_two-btn">
                    <img src="/assets/applelogo.png" alt="logo" />
                    <img src="/assets/apple.png" alt="logo" />
                  </button>
                </div>
              </div>
              <div className="box_two-iphone">
                <div>
                  <img src="/assets/iphone.png" alt="iphone" />
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ flexGrow: 1, borderRadius: "20px" }}
        className="bg-[#ffffff] px-8 md:px-12  py-6 mt-5 md:mt-8 mb-3"
      >
        <Grid
          container
          spacing={{ xs: 3, sm: 5, md: 3, lg: 4 }}
          columns={{ xs: 12, md: 12 }}
          justifyContent="center"
          alignItems="center"        >
          {QuickLinks.map((quicklink, index) => (
            <Grid item xs={4} sm={6} md={2} key={index}>
              <Box
                className=" w-11/12 flex flex-col items-center rounded-lg justify-center py-5 gap-3 md:gap-5 text-xs md:text-sm font-bold hover:border hover:shadow-md shadow-[#524cffa2] transition-all duration-100  border-0 border-[#524cffa2] border-solid"
                sx={{ backgroundColor: `${quicklink.bg}` }}
              >
                {quicklink.icons}
                {quicklink.name}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <div className="bg-[#fefefe] my-8 px-5 py-5 rounded-lg">
        <TransactionTable />
      </div>
    </Box>
  );
};

export default DashboardHome;
