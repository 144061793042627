export const slideUp ={
    hidden:{
        opacity: 0.6,
        y: 50,
        transition:{
            type: "spring",
            stiffness: 100,
            damping:10,
        },

    },
    show:{
        opacity:1,
        y:10,
        transition:{
            type: "spring",
            stiffness: 20,
            delay: 0.5,
        },
    }
}

export const slideLeft ={
    hidden:{
        opacity: 0.6,
        x: 60,
        y:20,
        transition:{
            type: "spring",
            stiffness: 200,
            damping:10,
        },

    },
    show:{
        opacity:1,
        x:10,
        y:5,
        transition:{
            type: "spring",
            stiffness: 30,
            delay: 0.5,
        },
    }
}
export const slideDown ={
    hidden:{
        opacity: 0.9,
        y: 50,
        x: 50,
        transition:{
            type: "spring",
            stiffness: 100,
            damping:10,
        },

    },
    show:{
        opacity:1,
        x:0,
        y:0,
        transition:{
            type: "spring",
            stiffness: 20,
            delay: 0.5,
        },
    }
}
export const slideRight={
    hidden:{
        opacity: 0.5,
        x: -50,
        y:10,
        transition:{
            type: "spring",
            stiffness: 100,
            damping:10,
        },

    },
    show:{
        opacity:1,
        x:0,
        y:0,
        transition:{
            type: "spring",
            stiffness: 20,
            delay: 0.5,
        },
    }
}
