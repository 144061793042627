import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoCheckmarkOutline } from "react-icons/io5";

const TransboxHeader = () => {
  const location = useLocation();
  const [stepper, setStepper] = useState<number>();

  useEffect(() => {
    const searchParams = location.pathname;
    let result =
      searchParams === "/dashboard/transfer/slide-one"
        ? 1
        : searchParams === "/dashboard/transfer/slide-two"
        ? 2
        : searchParams === "/dashboard/transfer/slide-three"
        ? 3
        : 0;
    setStepper(result);
  }, [location, stepper]);



  return (
    <>
      {" "}
      <div className="border-b border-[#DADCDF] pb-2">
        <ul className="flex flex-row justify-between items-center">
          <li
            className={`info_list ${
              stepper === 1
                ? "info_active"
                : stepper && stepper > 1
                ? "done"
                : "info_inactive"
            }`}
          >
            <div className={`info_cir text-xs`}>
              {stepper === 1 ? "1" : <IoCheckmarkOutline size={17} />}
            </div>
            <div className="flex flex-col">
              <span>{stepper === 1 ? "step 1" : ""}</span>
              <span>Select bank</span>
            </div>
          </li>
          <li className="info_list">{">"}</li>
          <li
            className={`info_list ${
              stepper === 2
                ? "info_active"
                : stepper && stepper > 2
                ? "done"
                : "info_inactive"
            }`}
          >
            <div className={`info_cir`}>
              {stepper && stepper > 2 ? <IoCheckmarkOutline size={17} /> : "2"}
            </div>
            <div className="flex flex-col">
              {stepper === 2 ? <span>step 2</span> : ""}
              <span>Amount</span>
            </div>
          </li>
          <li className="info_list">{">"}</li>
          <li
            className={`info_list ${
              stepper === 3 ? "info_active" : "info_inactive"
            }`}
          >
            <div className={`info_cir`}>3</div>
            <div className="flex flex-col">
              {stepper === 3 ? <span>step 3</span> : ""}
              <span>Summary</span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TransboxHeader;
