import { Button } from "@mui/material";

const TransferFinal = () => {
    return (
        <div>
          <div className="flex flex-col gap-6 md:flex-row justify-start md:justify-between items-start md:items-center">
            <div>
              <h1 className="text-2xl font-bold tracking-wider">Transfer</h1>
              <p className="text-[#7D8398] text-sm">
                Confirm Transactions Details{" "}
              </p>
            </div>
          </div>
          <div>
            <div className="text-center pt-3">
              <h2 className="font-bold text-sm">Transfer Amount</h2>
              <p className="text-[#3D7EBE] font-bold text-4xl">₦amount</p>
            </div>
            <div className="pt-10"> 
              <h3 className="text-xs font-bold pb-3">Transfer details</h3>
              <div className="flex items-center justify-between">
                <h4 className="text-sm text-[#323238]">Amount</h4>
                <span className="text-sm font-bold">$amount</span>
              </div>
              {/* <div className="flex items-center justify-between pt-2">
                <h4 className="text-sm text-[#323238]">Fees</h4>
                <span className="text-sm font-bold">computeCharge(amount)</span>
              </div> */}
              <div className="flex items-center justify-between pt-5">
                <h4 className="text-sm text-[#323238]">Total Debit</h4>
                <span className="text-sm font-bold">$amount</span>
              </div>

              <div
                id="modal-modal-description"
                className="border border-[#E1E1E6] flex items-center gap-2 mt-6 px-2 py-1"
              >
                <div className="modal_img ">
                  <img src="/assets/logo.png" alt="access" />
                </div>
                <div>
                  <h3 className="text-black font-bold">accountName </h3>
                  <p className="font-bold text-sm">
                    Acepacific Bank- accountNumber
                  </p>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              fullWidth
            >
              Proceed
            </Button>
          </div>
        </div>
      );
    }; 
    
    export default TransferFinal;