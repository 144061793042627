import { useEffect } from "react";
import ComingSoon from "../../../components/ComingSoon";

const BillPayments = () => {
  useEffect(() => {
    document.title = "Bill payment";
  });
  return <div>
    <ComingSoon page="Bill Payments" />
  </div>;
};

export default BillPayments;
