import { useEffect } from "react";
import ComingSoon from "../../../components/ComingSoon";

const Transactions = () => {
  useEffect(() => {
    document.title = "Transactions";
  });
  return (
    <div>
      <ComingSoon page="Transaction" />
    </div>
  );
};

export default Transactions;
