import React from "react";

const TransferHeader = () => {
  return (
    <>
      <div className="border-b border-[#DADCDF] pb-5">
        <h1 className="text-2xl font-bold tracking-wider pb-1   ">New Transfer </h1>
        <ul className="flex items-center justify-start gap-2 text-sm">
          <li className="text-[#3D7EBE]">Transfer</li>
          <li className="text-xs text-[#7D8398]">{">"}</li>
          <li className="text-[#7D8398]">New transfer</li>
        </ul>
      </div>
    </>
  );
};

export default TransferHeader;
