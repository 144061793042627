import React, { useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { motion } from "framer-motion";
import { slideUp, slideLeft, slideDown, slideRight } from "../../motion/motion";
import { Link } from "react-router-dom";
import "./AboutUs.css";
import Navbar from "../../components/Landing/Navbar";
import NavbarMobile from "../../components/Landing/NavbarMobile";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us";
  }, []);

  return (
    <>
      <div className="w-screen h-screen overflow-x-hidden">
        <div className="about bg-[#f7f7f5]">
          <div className="about__hero flex items-center text-white justify-center lg:justify-end relative">
            <Navbar />
            <div className="z-40">
              <NavbarMobile />
            </div>
            <motion.div
              viewport={{ amount: 0.2 }}
              variants={slideUp}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -top-56 left-64"
            >
              {/* <img src="../../../public/assets/landing/floating_image_01.png" alt="float" /> */}
            </motion.div>
            <motion.div
              viewport={{ amount: 0.4 }}
              variants={slideRight}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute bottom-60 left-8 z-50"
            >
              <img src="/assets/landing/floating_image_02.png" alt="float" />
            </motion.div>
            {/* <motion.div
              viewport={{ amount: 0.1 }}
              variants={slideDown}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -bottom-20 -left-16 z-40"
            >
              <img
                src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="float"
              />
            </motion.div> */}
            <motion.div
              viewport={{ amount: 0.3 }}
              variants={slideLeft}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute bottom-20 -right-36 z-10"
            >
              <img src="/assets/landing/floating_image_04.png" alt="float" />
            </motion.div>
            <div className="px-5 lg:pr-24 z-10 text-center lg:text-right">
              <h1 className="font-black text-5xl md:text-8xl pb-3">
                About Us
              </h1>
              <p className="text-2xl md:text-3xl md:pt-8 lg:pl-12">
                Where our hospitality and financial insight come together to
                provide a superior banking experience.
              </p>
            </div>
            <div className="absolute hero-wave">
              <img
                src="/assets/landing/floating_image_04.png"
                className="w-full"
                alt="wave"
              />
            </div>
          </div>
          <div className="about__intro pt-10 pb-14 mt-11 relative px-6 flex flex-col lg:flex-row gap-16 md:gap-8 items-center md:items-start">
            <div className="w-full md:w-1/2 z-10">
              {/* <h2 className="font-black text-4xl md:text-5xl pb-3 md:pb-1 text-[#181818]">
                Introduction
              </h2> */}
              <p className="text-lg md:text-2xl md:pt-8 mb-8 md:mb-4 text-[#181818]">
              Where our hospitality and financial insight come together to provide a superior banking experience.
              Ace Pacific Digital Bank was founded over 35 years ago with a commitment and drive to provide personal service, 
              lasting customer relationships, responsiveness and attention to detail. Several generations later in the future, 
              we still operate under these founding principles and continue the tradition of providing service excellence.
              We’re proud to be the hometown bank of the World, supporting businesses and families like you in achieving your 
              financial goals.
              </p>
              <Link to="/contact">
                <Button variant="outlined" className="rounded-full">
                  Contact Us
                </Button>
              </Link>
            </div>
            <div className="w-full lg:w-1/2 flex items-center z-10">
              <Box sx={{ flexGrow: 2 }}>
                <Grid
                  container
                  spacing={{ xs: 2, sm: 6 }}
                  columns={{ xs: 2, sm: 12, md: 12 }}
                  className="px-1 md:px-5"
                >
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white">
                      <img
                        src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="Personal Banking"
                        className="w-full"
                      />
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Personal Banking
                        </h2>
                        <p className="text-sm md:text-lg pt-4">
                        Your goals and dreams are personal. Your banking partner should be too.
                        At Ace Pacific Digital Bank, we take great care in delivering personal 
                        banking services. Whether you visit us online or walk through our doors, 
                        you’ll be supported by a team that will help you achieve financial security – now and in the future.
                        </p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white lg:mt-10">
                      <img
                        src="/assets/landing/service_03-1280x960.jpg"
                        alt="Business Banking"
                        className="w-full"
                      />
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Business Banking
                        </h2>
                        <p className="text-sm md:text-lg pt-4">
                        We’re here to help you start or grow your business. We’re invested in your success.
                        From startups to more mature operations, our business banking services are designed 
                        to help your business succeed. Our services help you to simplify business processes 
                        and empower you with the tools needed to manage and grow your business. 
                        </p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white">
                      <img
                        src="https://plus.unsplash.com/premium_photo-1661659232931-1d4e811b28c2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="Mortgages"
                        className="w-full"
                      />
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Mortgages
                        </h2>
                        <p className="text-sm md:text-lg pt-4">
                        We’ll take the complexity out of buying a house so you can focus on making it a home.
                        Whether you’re buying your first home, a dream home or an income property, our mortgage 
                        lenders will work hard to find a loan product that’s right for you. Our attention to 
                        detail allows us to guide you through the process of securing competitive, low rates.
                        </p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white lg:mt-10">
                      <img
                        src="https://plus.unsplash.com/premium_photo-1661342516166-4a25ea17c27d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="Investments"
                        className="w-full"
                      />
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Investments
                        </h2>
                        <p className="text-sm md:text-lg pt-4">
                          Investments in digital assets and Web3 companies are highly speculative and involve a high degree of risk. 
                          Competitive pricing and ratings may occur too. Customize your ultimate trading experience with a platform 
                          built for experienced traders. Trade high-volume and liquidity on multiple asset classes through a robust 
                          platform like Ace Pacific Digital Assets Management.
                        </p>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
          <div className="about__personal flex items-center justify-center md:justify-start text-white py-28 relative">

            <div className="px-6 lg:px-16 z-20 text-center md:text-left">
              <h2 className="font-black text-4xl md:text-6xl pb-3">
                Personal Banking
              </h2>
              <p className="text-lg md:text-xl lg:text-2xl md:pt-8 mb-5">
                At Ace Pacific Digital Bank, we take great care in delivering personal banking services. Whether you visit us online or walk through our doors, you’ll be supported by a team that knows banking, knows you, and knows how to help you achieve your financial goals.
              </p>
              <Link to="/personal-banking">
                <Button variant="outlined" className="rounded-full">
                  Learn More
                </Button>
              </Link>
            </div>
            <motion.div
              viewport={{ amount: 0.3 }}
              variants={slideUp}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -top-16 md:-top-44 lg:-top-72 right-8"
            >
              <img
                src="https://images.unsplash.com/photo-1573164574394-8883440a0b4f?q=80&w=1080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="float"
                className="rounded-full"
              />
            </motion.div>
           
          </div>
          <div className="about__values px-6 lg:px-28 bg-[#f7f7f5] flex flex-col items-center justify-center relative py-28">
            <div className="w-full md:w-3/4 lg:w-3/5 text-center">
              <h2 className="font-black text-4xl md:text-6xl pb-3">
                Our Values
              </h2>
              <p className="text-lg md:text-2xl md:pt-8 mb-8 text-[#181818]">
                At Ace Pacific Digital Bank, we’re passionate about building a better future by understanding your aspirations and supporting your success. We stay true to our values of integrity, innovation, and community to deliver exceptional banking experiences.
              </p>
              <Grid
                container
                spacing={{ xs: 2, sm: 6 }}
                columns={{ xs: 2, sm: 12, md: 12 }}
                className="px-1 md:px-5"
              >
                <Grid item xs={6} sm={6}>
                  <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white">
                    <img
                      src="https://images.unsplash.com/photo-1516321497487-e288fb19713f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Community"
                      className="w-full"
                    />
                    <div className="text-black px-6 py-8">
                      <h2 className="text-xl md:text-2xl font-bold">
                        Community
                      </h2>
                      <p className="text-sm md:text-lg pt-4">
                        We’re deeply committed to making a positive impact in the communities we serve.
                      </p>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white lg:mt-10">
                    <img
                      src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Innovation"
                      className="w-full"
                    />
                    <div className="text-black px-6 py-8">
                      <h2 className="text-xl md:text-2xl font-bold">
                        Innovation
                      </h2>
                      <p className="text-sm md:text-lg pt-4">
                        We embrace cutting-edge technology to bring you the most advanced banking solutions.
                      </p>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1661319063327-ccb1da3003a4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Integrity"
                      className="w-full"
                    />
                    <div className="text-black px-6 py-8">
                      <h2 className="text-xl md:text-2xl font-bold">
                        Integrity
                      </h2>
                      <p className="text-sm md:text-lg pt-4">
                        Our business is built on trust. We uphold the highest standards of integrity in all our actions.
                      </p>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white lg:mt-10">
                    <img
                      src="https://images.unsplash.com/photo-1462045504115-6c1d931f07d1?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Excellence"
                      className="w-full"
                    />
                    <div className="text-black px-6 py-8">
                      <h2 className="text-xl md:text-2xl font-bold">
                        Excellence
                      </h2>
                      <p className="text-sm md:text-lg pt-4">
                        We strive for excellence in everything we do, from customer service to financial solutions.
                      </p>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="bg-[#191919] text-white w-full  mt-12 py-6 justify-around text-center md:flex ">
              <p>©2023 Ace Pacific Bank. All rights reserved</p>
              <ul className="flex gap-6 text-xs md:text-sm items-center justify-center mt-3">
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
                <li>
                  <a href="/">Cookie Policy</a>
                </li>
              </ul>
            </div>
      </div>
    </>
  );
};

export default AboutUs;
