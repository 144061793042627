import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const TransferOptions = () => {
  return (
    <div className="mt-24 flex items-center justify-center ">
      <Box
        sx={{
          minWidth: 280,
          background: "#ffffff",
        }}
        className="px-6  py-8 border border-[#DADCDF] "
      >
        <h2 className="text-lg font-bold ">Transfer Money to?</h2>
        <div className="flex flex-col justify-center  items-center gap-3 pt-5">
          <Link to={"/dashboard/transfer/acebank"}>
            <Box className="flex flex-row gap-2 items-center py-2 px-2.5 border border-[#DADCDF]">
              <img src="/assets/logo.png" alt="Logo" className="w-5" />
              <span className="text-[#3D7EBE] text-xs font-bold">
                ACE PACIFIC BANK
              </span>
            </Box>
          </Link>
          <Link
            to={"/dashboard/transfer/slide-one"}
            className="block text-sm bg-[#3D7EBE] text-[#ffff] py-2 px-10 font-bold border border-[#DADCDF]"
          >
            Other Bank
          </Link>
        </div>
      </Box>
    </div>
  );
};

export default TransferOptions;
