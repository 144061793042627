import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL, DEFAULT_WITHIN_BANK_VALUE } from "../constants/API_BASE_URL";
//import axios, { AxiosResponse } from "axios";

interface transferState {
  amount: number;
  accountNumber: string; 
  accountName: string;
  pin: string;
  isLoading: boolean;
  withinBank: boolean;
  message: string;
  success: boolean; 
  transactionNarration: string;
  recipientWalletAccountNumber : string;
}

interface IntraTransfer {
  amount: number;
  recipientWalletAccountNumber: string;
  senderWalletAccountNumber: string;
  transactionNarration: string;
  transactionPin: string
}


const initialState: transferState = {
  amount: 0,
  accountNumber: "",
  accountName: "",
  pin: "",
  isLoading: false,
  withinBank: false,
  message: "",
  success: true,
  transactionNarration:"",
  recipientWalletAccountNumber:""
};

export const getUserName = createAsyncThunk(
  "transfer/getUserName",
  async (credential: { accountNumber: string }) => {
    try {
      const endpoint = `${API_BASE_URL}/api/Wallet/GetUserAccountAdmin/${credential.accountNumber}`;
      const options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        }),
      };
      const response = await fetch(endpoint, options);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      return { message: "Try again later" };
    }
  }
);

export const getOtherBankUserName = createAsyncThunk(
  "transfer/getUserName",
  async (credential: { accountNumber: string; bankName: string }) => {
    try {
      const endpoint = `${API_BASE_URL}/api/Wallet/GetUserAccountAdmin/`;
      const options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        }),
        body: JSON.stringify(credential),
      };
      const response = await fetch(endpoint, options);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.log(error);
      return { message: "Try again later" };
    }
  }
);
export const intraTransfer = createAsyncThunk(
  "transfer/getUserName",
  async (credential: IntraTransfer) => { 
    try {
      const endpoint = `${API_BASE_URL}/api/Wallet/MakeIntraTransfer`;
      const options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        }),
        body: JSON.stringify(credential),
      };
      const response = await fetch(endpoint, options);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.log(error);
      return { message: "Try again later" };
    }
  }
);

const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    cleanUp(state) {
      state.message = "";
      state.success = false;
    },
    getAmount(state, action) {
      const { amount, transactionNarration , recipientWalletAccountNumber} = action.payload;
      state.amount = parseInt(amount, 10);
      state.transactionNarration = transactionNarration;
      state.recipientWalletAccountNumber = recipientWalletAccountNumber
    },
    addPin(state, action){
      state.pin = action.payload
    },
    setWithinBank(state, action) {
      state.withinBank = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserName.fulfilled, (state, action) => {
        console.log(action.payload);
        if(action.payload.result){
          const result = action.payload.result;
          state.accountName = `${result.firstName} ${result.lastName}`;
          state.accountNumber = result.accountNumber;
          state.withinBank = true;
          state.success = true
        }else{
          state.message= action.payload.message
        }
        state.isLoading = false;
      })
      .addCase(getUserName.rejected, (state) => {
        state.isLoading = false;
        state.message = "Opps something went wrong";
      });
  },
});

export const { cleanUp, getAmount, addPin } = transferSlice.actions;
export default transferSlice.reducer;
