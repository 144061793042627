import React from "react";

interface props {
  page: string;
}
const ComingSoon: React.FC<props> = ({ page }) => {
  return (
    <div
      style={{ height: "70vh", width: "100%" }}
      className="flex items-center justify-center "
    >
      <h1 className="text-[#242527] text-3xl text-center">{page} page Coming Soon...</h1>
    </div>
  );
};

export default ComingSoon;
