import React, { useState } from "react";
import { FormControl, Box } from "@mui/material";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  err?: string;
  placeholder?: string | "";
}

const InputFieldPassword: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  err,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <FormControl>
        <Box sx={{ position: "relative" }} mb={2}>
          <label htmlFor={name} className="text-sm">
            {label}
          </label>
          <input
            type={showPassword ? "text" : type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`text-[#3B4752] w-full py-1.5 px-3 outline-0 focus:shadow-xl border-[#cfd8e1] border rounded  ${
              err && value.length <= 0 ? "border-red-500" : ""
            } focus:border-[#3d7ebe]`}
          />
          {err && value.length <= 0 ? (
            <div className="text-red-500 pt-1 text-sm">{err}</div>
          ) : (
            ""
          )}

          <Box
            sx={{
              position: "absolute",
              right: "10px",
              top: "33px",
              background: "#fff",
            }}
            className="icon cursor-pointer"
            onClick={togglePassword}
          >
            {showPassword ? (
              <AiOutlineEye size={20} color="#333" />
            ) : (
              <AiOutlineEyeInvisible size={20} color="#333" />
            )}
          </Box>
        </Box>
      </FormControl>
    </>
  );
};

export default InputFieldPassword;
