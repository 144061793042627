import React from "react";
import ComingSoon from "../../../components/ComingSoon";

const Notification = () => {
  return (
    <div>
      <ComingSoon page="Notification" />
    </div>
  );
};

export default Notification;
