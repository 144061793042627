import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Stack, Box, Badge } from "@mui/material";
import "./DashboardHeader.css";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { LuUserCircle2 } from "react-icons/lu";
import { BsPatchQuestion } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import moment from "moment";
import { IoReceiptOutline } from "react-icons/io5";
import { useAppSelector } from "../../../Redux/hooks";
import { RiLogoutCircleRLine } from "react-icons/ri";
import LogoutModal from "../../Modals/LogoutModal";
import { getTimeOfDay } from "../../../constants/API_BASE_URL";

const DropDown = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleLogout = () => {setShowModal(!showModal);
  };

  const MenuItems = [
    {
      path: "/dashboard/profile",
      name: "Profile",
      icon: <LuUserCircle2 size={20} />,
    },
    {
      path: "/support",
      name: "Support",
      icon: <BsPatchQuestion size={20} />,
    },
    {
      path: "/privacy-&-terms",
      name: "Privacy & Terms",
      icon: <IoReceiptOutline size={20} />,
    },
  ];
  return (
    <div>
      <div className="dropdwn">
        {MenuItems.map((menuItem, index) => (
          <div key={index}>
            <Link to={menuItem.path} className="w-full flex items-center gap-2">
              {menuItem.icon}
              {menuItem.name}
            </Link>
          </div>
        ))}
        <div>
          <p
            className="w-full flex items-center gap-2 cursor-pointer"
            onClick={handleLogout}
          >
            <RiLogoutCircleRLine size={20} />
            Logout
          </p>
        </div>
      </div>

      <LogoutModal enable={showModal} setIsSubmitting={setShowModal} />
    </div>
  );
};

const DashboardHeader = () => {
  const { user } = useAppSelector(({ auth }) => auth);
  const location = useLocation();
  const [isDashboardPage, setIsDashboardPage] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState(getTimeOfDay());
  const [pageReloaded, setPageReloaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const checkIfOnDashboard = () => {
      const isOnDashboard = location.pathname;
      if (isOnDashboard === "/dashboard") {
        setIsDashboardPage(true);
      } else {
        setIsDashboardPage(false);
      }
    };
    
    checkIfOnDashboard();
    setTimeOfDay(getTimeOfDay());
    setIsOpen(false)
  }, [isDashboardPage, location]);
  
  const openDropDown = () => {
    setIsOpen(!isOpen);
  };

  const yourDate: Date = new Date(); // Replace this with your actual date

  // Format the date using moment
  const formattedDate = moment(yourDate).format("Do MMM YYYY HH:mm");
  return (
    <div className="Dashboard_header">
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        alignContent={{ xs: "flex-end", s: "" }}
        justifyContent={{
          xs: "flex-end",
          sm: `${isDashboardPage ? "space-between" : "flex-end"}`,
        }}
      >
        {isDashboardPage && (
          <div className="dashHead">
            <h1>{timeOfDay} {user?.userName} 👌🏼</h1>
            <p>{formattedDate}</p>
          </div>
        )}
        <div className={`noti ${isDashboardPage ? "left" : ""}`}>
          <Badge color="warning" badgeContent=" " variant="dot">
            <Box
              sx={{
                width: 35,
                height: 35,
                borderRadius: 2,
                background: "#ffff",
              }}
              className="box shadow-2xl shadow-[#0c0d0e9d] cursor-pointer"
            >
              <Link to={"/dashboard/notification"}>
                <IoNotificationsOutline size={20} />
              </Link>
            </Box>
          </Badge>
          <Box className="prf py-1 px-1 shadow-2xl  shadow-[#2926267c]">
            <Box
              sx={{
                width: 35,
                height: 35,
                borderRadius: "50%",
                overflow: "hidden",
              }}
              className="bg-[#4583bf] flex justify-center items-center"
            >
              {user?.profilePictureUrl ? (
                <img src={`${user?.profilePictureUrl}`} alt="P" />
              ) : (
                <div className="text-white text-xs">
                  {user?.initial}
                </div>
              )}
            </Box>
            <p className="font-semibold text-sm">
              {user?.firstName} {user?.lastName}
            </p>
            <div
              className={` ml-1 text-lg cursor-pointer inline ${
                isOpen ? "open" : "close"
              }`}
              onClick={openDropDown}
            >
              {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              <DropDown />
            </div>
          </Box>
        </div>
      </Stack>
    </div>
  );
};

export default DashboardHeader;
