import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./modal.css";
import { logout } from "../../Redux/authSlice";
import { useAppDispatch } from "../../Redux/hooks";

interface ModalProps {
  enable: boolean;
  setIsSubmitting: (value: boolean) => void;
}

const LogoutModal: React.FC<ModalProps> = ({ enable, setIsSubmitting }) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
    setIsSubmitting(!enable);
  };
  return (
    <div>
      <Modal
        open={enable}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="box_modal log">
          <h2
            id="modal-modal-title"
            className="font-bold text-sm leading-3 pb-3"
          >
            Are you sure you want to logout ?
          </h2>
          <div className="flex items-center justify-center gap-5">
            <Button
              variant="contained"
              size="medium"
              fullWidth
              sx={{
                mt: 2,
              }}
              onClick={handleLogout}
            >
              logout
            </Button>
            <Button
              variant="outlined"
              size="medium"
              fullWidth
              sx={{
                mt: 2,
              }}
              onClick={() => setIsSubmitting(!enable)}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LogoutModal;
