import "./DashSidebar.css";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { PiPaperPlaneTiltLight } from "react-icons/pi";
import { TbUserHexagon } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { IoReceiptOutline } from "react-icons/io5";
import { LuSettings } from "react-icons/lu";
import { LuUserCircle2 } from "react-icons/lu";
import { BsPatchQuestion } from "react-icons/bs";
import { RiLogoutCircleRLine } from "react-icons/ri";

import { NavLink, Link } from "react-router-dom";
import LogoutModal from "../../Modals/LogoutModal";

const DashSidebar = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const handleLogout = () => {
    setShowModal(!showModal)
  }
  const MenuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <RxDashboard size={20} />,
    },
    {
      path: "/dashboard/transfer",
      name: "Transfer",
      icon: <PiPaperPlaneTiltLight size={20} />,
    },
    {
      path: "/dashboard/bill-payment",
      name: "Bill payment",
      icon: <TbUserHexagon size={20} />,
    },
    {
      path: "/dashboard/airtime-data",
      name: "Airtime and Data",
      icon: <HiOutlineDevicePhoneMobile size={20} />,
    },
    {
      path: "/dashboard/transactions",
      name: "Transactions",
      icon: <IoReceiptOutline size={20} />,
    },
  ];
  const ManageItem = [
    {
      path: "/dashboard/profile",
      name: "Profile",
      icon: <LuUserCircle2 size={20} />,
    },
    // {
    //   path: "/dashboard/settings",
    //   name: "Settings",
    //   icon: <LuSettings size={20} />,
    // },
  ];

  return (
    <div className="dashsidebar_container px-6 pt-12 ">
      <div className="Dashboard_cont">
      <Box className="flex flex-row gap-2 content-center pb-8  ">
        <img src="/assets/logo.png" alt="Logo" className="w-5" />
        <Typography color="#3D7EBE" fontSize={"13px"} fontWeight={700}>
          ACE PACIFIC BANK
        </Typography>
      </Box>

      <div className="sidebar_links">
        <div>
          <Box className="pb-10">
            <h3 className="text-sm text-[#628ddd] pb-4">Overview</h3>
            <ul className="flex flex-col gap-4">
              {MenuItems.map((menuItems, index) => (
                <li key={index}>
                  <NavLink
                    to={menuItems.path}
                    className={({
                      isActive,
                    }) => `flex content-center justify-start gap-2  text-[#628ddd] 
                     ${isActive ? "text-[#45a2ff] font-semibold" : ""}`}
                  >
                    {menuItems.icon}
                    {menuItems.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Box>
          <Box className="pb-16">
            <h3 className="text-sm text-[#628ddd] pb-4">Manage</h3>
            <ul className="flex flex-col gap-4">
              {ManageItem.map((manageItems, index) => (
                <li key={index}>
                  <NavLink
                    to={manageItems.path}
                    className={({ isActive }) => `flex content-center justify-start gap-2 text-[#628ddd] 
    ${isActive ? "text-[#3b9dff] font-semibold" : ""}`}
                  >
                    {manageItems.icon}
                    {manageItems.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Box>
        </div>
        <Box>
          <ul className="flex flex-col gap-4">
            {/* <li>
              <Link
                to={"/support"}
                className="flex content-center justify-start gap-2 text-[#628ddd] "
              >
                <BsPatchQuestion size={20} />
                Support
              </Link>
            </li> */}
            <li className="flex content-center justify-start gap-2 text-[#628ddd] cursor-pointer" onClick={handleLogout}>
              <RiLogoutCircleRLine size={20} />
              Logout
            </li>
          </ul>
          </Box>
          <LogoutModal enable={showModal} setIsSubmitting={setShowModal} />
      </div>
    </div>
    </div>
  );
};

export default DashSidebar;
