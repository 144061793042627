import { useState, useEffect, ChangeEvent } from "react";
import { useAppSelector } from "../../../Redux/hooks";
import axios from "axios";
import { API_BASE_URL } from "../../../constants/API_BASE_URL";

const Profile = () => {
  const { user } = useAppSelector(({ auth }) => auth);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUploaded, setImageUploaded] = useState(false); // State to track image upload

  useEffect(() => {
    document.title = "Profile";
    console.log("User object:", JSON.stringify(user, null, 2));
  }, [user, imageUploaded]); // Re-render when user or imageUploaded changes

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      // Reset image upload state when selecting a new file
      setImageUploaded(false);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !user) return;

    const formData = new FormData();
    formData.append("userId", user.userId);
    formData.append("imageFile", selectedFile);

    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/User/UploadUserImageCloudinary?userId=${user.userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload successful:", response.data);
      // Update image upload state
      setImageUploaded(true);
      // Optionally, update the user's profile picture URL here
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return (
    <div key={imageUploaded ? "uploaded" : "notUploaded"} className="pt-8">
      <div className="bg-white shadow-sm shadow-slate-200 rounded py-6">
        <div className="flex justify-center">
          <div className="w-40 md:w-52 h-40 md:h-52 rounded-full bg-[#4583bf] flex justify-center items-center overflow-hidden">
            {user?.profilePictureUrl && !imageUploaded ? (
              <img
                src={user.profilePictureUrl}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="text-white text-2xl md:text-4xl">
                {user?.initial}
              </div>
            )}
          </div>
        </div>
        {!user?.profilePictureUrl && !imageUploaded && (
          <div className="flex justify-center mt-4">
            <input type="file" onChange={handleFileChange} />
            <button
              onClick={handleUpload}
              className="bg-blue-500 text-white px-4 py-2 rounded ml-2"
            >
              Upload Image
            </button>
          </div>
        )}
      </div>
      <div className="bg-white shadow-sm shadow-slate-200 rounded py-1 my-6">
        <div className="w-full flex flex-col gap-8 px-5">
          <div className="w-full flex justify-between items-center">
            <h3 className="font-semibold text-sm md:text-lg">Full Name :</h3>
            <span className="text-xs md:text-sm font-light text-[#1d1c1c]">
              {user?.firstName} {user?.lastName}
            </span>
          </div>

          <div className="w-full flex justify-between items-center">
            <h3 className="font-semibold text-sm md:text-lg">Username :</h3>
            <span className="text-xs md:text-sm font-light text-[#1d1c1c]">
              {user?.userName}
            </span>
          </div>

          <div className="w-full flex justify-between items-center">
            <h3 className="font-semibold text-sm md:text-lg">Phone number :</h3>
            <span className="text-xs md:text-sm font-light text-[#1d1c1c]">
              {user?.phoneNumber}
            </span>
          </div>

          <div className="w-full flex justify-between items-center">
            <h3 className="font-semibold text-sm md:text-lg">Email address :</h3>
            <span className="text-xs md:text-sm font-light text-[#1d1c1c]">
              {user?.email}
            </span>
          </div>

          <div className="w-full flex justify-between items-center">
            <h3 className="font-semibold text-sm md:text-lg">Account Number :</h3>
            <span className="text-xs md:text-sm font-light text-[#1d1c1c]">
              {user?.accountNumber}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
