import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BsBank } from "react-icons/bs";
import { MdOutlineSportsSoccer } from "react-icons/md";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../Redux/hooks";
import { API_BASE_URL, formatDateObject, formatNumberWithCommas } from "../../constants/API_BASE_URL";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fefefe",
    color: "#121212",
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
    color: "#121212",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface Transaction {
  transactionType: number;
  transactionNarration: string;
  accountNumber: string;
  bankName: string;
  transactionAmount: string;
  dateCreated: string;
  status: string;
  recipientAccountName: string;
}


const TransactionTable = () => {
  const { user } = useAppSelector(({ auth }) => auth);
  const [transactionHistory, setTransactionHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/Wallet/ViewUserTransactionHistory?userId=${user?.userId}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
          }),
        });
        const data = await response.json();

        if (data.successful && data.hasResult) {
          setTransactionHistory(data.result);
        } else {
          console.error("Error fetching transaction history:", data.message);
        }
      } catch (error) {
        console.error("Error fetching transaction history:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <h2 className="text-lg font-bold">Transaction</h2>
      <p className="text-[#7D8398] text-sm font-normal">
        An overview of all your most recent transactions
      </p>
      <div className="p-0 md:px-2 pt-4">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell align="left">Details </StyledTableCell>
                <StyledTableCell align="left">
                  Account No.
                </StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>

                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {transactionHistory.length === 0 ? (
    <StyledTableRow>
      <StyledTableCell colSpan={7} align="center">
        <p>No transactions found.</p>
      </StyledTableCell>
    </StyledTableRow>
  ) : (
    transactionHistory.map((row: Transaction, index: number) => (
      <StyledTableRow key={index}>
        <StyledTableCell component="th" scope="row">
          <div className="font-bold bg-red-200 py-2 rounded-lg flex justify-center items-center">
            {row.transactionType === 0 ? <BsBank size={20} /> : ""}
            {row.transactionType === 1 ? (
              <MdOutlineSportsSoccer size={18} />
            ) : (
              ""
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell align="left">
          <span className="font-bold ">{row.transactionNarration}</span>
        </StyledTableCell>
        <StyledTableCell align="left">
          <span className="font-normal text-[#7D8398]">
            {row.recipientAccountName}
          </span>
        </StyledTableCell>
        <StyledTableCell align="left">
          <span className="font-normal text-[#7D8398]">
            ${formatNumberWithCommas(row.transactionAmount)}.00
          </span>
        </StyledTableCell>
        <StyledTableCell align="left">
          <span className=" font-normal text-[#7D8398] ">{formatDateObject(row.dateCreated)}</span>
        </StyledTableCell>
        <StyledTableCell align="left">
          <span className="font-normal bg-green-100 px-3 py-1 rounded-full">
            {row.status}
          </span>
        </StyledTableCell>
      </StyledTableRow>
    ))
  )}
</TableBody>

          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default TransactionTable;
