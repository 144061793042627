import { useEffect } from "react";
import { useAppSelector } from "../../Redux/hooks";
import { Box, Typography, Grid } from "@mui/material";
import { CiFacebook, CiInstagram, CiTwitter, CiLinkedin } from "react-icons/ci";
import "./LandingPage.css";
import Navbar from "../../components/Landing/Navbar";
import { motion } from "framer-motion";
import { slideUp, slideLeft, slideDown, slideRight } from "../../motion/motion";
import NavbarMobile from "../../components/Landing/NavbarMobile";
import { Link } from "react-router-dom";

const LandingPage = () => {
  useEffect(() => {
    document.title = "Home";
  });
  const { isLoggedIn } = useAppSelector(({ auth }) => auth);
  return (
    <>
      <div className="w-screen h-screen overflow-x-hidden">
        <div className="main bg-[#f7f7f5] ">
          <div className="main__hero flex items-center text-white justify-center lg:justify-end relative">
            <Navbar />
            <div className="z-40">
              <NavbarMobile />
            </div>
            <motion.div
              viewport={{ amount: 0.2 }}
              variants={slideUp}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -top-56 left-64"
            >
              <img src="/assets/landing/floating_image_07.png" alt="float" />
            </motion.div>
            <motion.div
              viewport={{ amount: 0.4 }}
              variants={slideRight}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute bottom-60 left-8 z-50"
            >
              <img src="/assets/landing/floating_image_02.png" alt="float" />
            </motion.div>
            <motion.div
              viewport={{ amount: 0.5 }}
              variants={slideDown}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -bottom-20  -left-16 z-40"
            >
              <img
                src="/assets/landing/floating_image_04.png"
                alt="float"
                className="w-11/12"
              />
            </motion.div>
            <motion.div
              viewport={{ amount: 0.3 }}
              variants={slideLeft}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute bottom-20  -right-36 z-10"
            >
              <img
                src="/assets/landing/floating_image_03.png"
                alt="float"
                className="w-11/12"
              /> 
            </motion.div>
            <div className="px-5 lg:pr-24 z-10">
              <h1 className="font-black text-5xl md:text-8xl  pb-3">
                Your Vision. <br />{" "}
                <span className="md:pl-24"> Our Passion.</span>
              </h1>
              <p className="text-2xl md:text-3xl md:pt-8 lg:pl-12">
                Ace Pacific global banking system.
              </p>
            </div>
            <div className="absolute hero-wave ">
              <img
                src="/assets/landing/Wave_White_bottom_left_shape_01.png"
                className="w-full"
                alt="wave"
              />
            </div>
          </div>
          <div className="main__service pt-10 pb-14 mt-11  relative px-6 flex flex-col lg:flex-row gap-16 md:8 items-center md:items-start ">
            <div className="w-full md:w-1/2 z-10">
              <h2 className="font-black text-4xl md:text-5xl pb-3 md:pb-1 text-[#181818]">
                Business banking made better
              </h2>
              <h4 className="text-lg  md:text-2xl md:pt-8 mb-8 md:mb-4 text-[#181818]">
                Experience the tradition, great and the natural banking services
                at Ace Pacific Bank
              </h4>
              <p className="text-sm md:text-lg text-[#181818]">
                At Ace Pacific Bank, we are committed to responding to your
                business needs quickly and effectively. As part of your business
                community, we are passionate about your success and the growth
                of our community as a whole. We are dedicated to serving you,
                and our members, and are uniquely structured to ensure your
                needs will always come first, because your both a customer and
                an owner of Ace Pacific Bank.
              </p>

              <ul className="text-sm gap-2 flex flex-col list-disc ml-4 my-10 text-[#181818]">
                <li>Cashless Banking System</li>
                <li>Secured Chip Cards</li>
                <li>Corporate Financial Advisory</li>
                <li>Secured Ebanking</li>
                <li>Shares and Stock Advisory</li>
              </ul>
              <Link to="/sign-up">
                <button className="block  md:inline-block rounded-full bg-transparent px-8 py-4 text-[#1976d2] hover:text-white font-semibold tracking-wider border-2 border-[#1976d2] hover:bg-[#1976d2]">
                {" "}
                Create Account
                </button>
                </Link>
            </div>
            <div className="w-full lg:w-1/2 flex items-center z-10">
              <Box sx={{ flexGrow: 2 }}>
                <Grid
                  container
                  spacing={{ xs: 2, sm: 6 }}
                  columns={{ xs: 2, sm: 12, md: 12 }}
                  className="px-1 md:px-5"
                >
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white">
                      <div className="w-full">
                        <img
                          src="/assets/landing/service_01-1280x960.jpg"
                          alt=""
                          className="w-full"
                        />
                      </div>
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Business banking
                        </h2>
                        <p className="font-semibold text-sm md:text-lg pt-4">
                          Read More
                        </p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white lg:mt-10">
                      <div className="w-full">
                        <img
                          src="/assets/landing/service_02-1280x960.jpg"
                          alt=""
                          className="w-full"
                        />
                      </div>
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Corporate banking
                        </h2>
                        <p className="font-semibold text-sm md:text-lg pt-4">
                          Read More
                        </p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white">
                      <div className="w-full">
                        <img
                          src="/assets/landing/service_03-1280x960.jpg"
                          alt=""
                          className="w-full"
                        />
                      </div>
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Financial Advisory
                        </h2>
                        <p className="font-semibold text-sm md:text-lg pt-4">
                          Read More
                        </p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box className="rounded-lg overflow-hidden border border-[#dedede] bg-white lg:mt-10">
                      <div className="w-full">
                        <img
                          src="/assets/landing/service_04-1280x960.jpg"
                          alt=""
                          className="w-full"
                        />
                      </div>
                      <div className="text-black px-6 py-8">
                        <h2 className="text-xl md:text-2xl font-bold">
                          Retail banking
                        </h2>
                        <p className="font-semibold text-sm md:text-lg pt-4">
                          Read More
                        </p>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>

            <div className="absolute bottom-wave left-0">
              <img
                src="/assets/landing/white_bottom_wave_01.png"
                className="w-full"
                alt="wave"
              />
            </div>
          </div>
          <div className="main__abtOne  relative flex items-center justify-center md:justify-start text-white py-28">
            <div className="absolute top-wave">
              <img
                src="/assets/landing/top_wave_01.png"
                className="w-full"
                alt="wave"
              />
            </div>
            <div className="px-6 lg:px-16 z-20">
              <h2 className="font-black text-4xl md:text-6xl pb-3 ">
                Discover our lovely and <br /> profitable bank
              </h2>
              <p className="text-lg md:text-xl lg:text-2xl md:pt-8  mb-5 ">
                Thats business banking where people still matter,
                <br /> where responsiveness counts, where local decision making
                is a priority.
              </p>
              <button className="block  md:inline-block  rounded-full bg-[#1976d2] px-8 py-4 text-white font-semibold tracking-wider">
                {" "}
                Suscribe
              </button>
            </div>
            <motion.div
              variants={slideUp}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -top-4  left-24 z-10"
            >
              <img
                src="/assets/landing/floating_image_03.png"
                alt="float"
                className="w-11/12"
              />
            </motion.div>
            <motion.div
              variants={slideDown}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -top-4  right-72 z-10"
            >
              <img
                src="/assets/landing/floating_image_05.png"
                alt="float"
                className="w-11/12"
              />
            </motion.div>
            <motion.div
              variants={slideLeft}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -bottom-36 -left-80 z-10"
            >
              <img
                src="/assets/landing/floating_image_04.png"
                alt="float"
                className="w-11/12"
              />
            </motion.div>
            <div className="absolute bottom-wave">
              <img
                src="/assets/landing/Wave_White_bottom_right_shape_02.png"
                className="w-full"
                alt="wave"
              />
            </div>
          </div>
          <div className="main__abtTwo  z-20 relative flex items-center justify-center md:justify-end md:text-right text-white">
            <div className="absolute top-wave">
              <img
                src="/assets/landing/top_wave_02.png"
                className="w-full"
                alt="wave"
              />
            </div>
            <div className="px-6 lg:px-16 pt-2 md:w-4/6 z-20">
              <h2 className="font-black text-4xl md:text-6xl  pb-1">
                Business and Investment Banking made better
              </h2>
              <p className="text-lg md:text-lg lg:text-2xl md:pt-8  mb-5 text-left ">
                At Ace Pacific Bank, we are committed to responding to your
                business needs quickly and effectively. As part of your business
                community, we are passionate about your success and the growth
                of our community as a whole.{" "}
              </p>
            </div>
            <motion.div
              viewport={{ amount: 0.3 }}
              variants={slideDown}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -top-56  left-64 z-10"
            >
              <img
                src="/assets/landing/floating_image_06.png"
                alt="float"
                className="w-11/12"
              />
            </motion.div>
            <motion.div
              viewport={{ amount: 0.3 }}
              variants={slideUp}
              initial="hidden"
              whileInView="show"
              className="float hidden md:block absolute -bottom-14  right-44 z-10"
            >
              <img
                src="/assets/landing/floating_image_07.png"
                alt="float"
                className="w-11/12"
              />
            </motion.div>
            <div className="absolute bottom-wave">
              <img
                src="/assets/landing/Wave_White_bottom_left_shape_02.png"
                className="w-full h-5/6"
                alt="wave"
              />
            </div>
          </div>
        </div>
        <div className="footer bg-[#F7F7F5] w-screen pt-16 mt-7">
          <div className="px-8">
            <div className="block md:flex justify-between m-auto gap-8 md:gap-5 items-center md:w-11/12">
              <h1 className="text-2xl w-full md:w-5/12 lg:w-6/12 lg:text-4xl font-medium1 pb-6 text-[#181818]">
                Subscribe to our Newsletter
              </h1>
              <div className="w-full md:w-2/5 flex-grow block md:w md:relative">
                <input
                  type="text"
                  placeholder="Your email"
                  className="placeholder:text-[#a17575] placeholder:font-normal inline-block w-full py-4 px-3 border-1 border-[#e2e2e2] outline-0 rounded-full mb-3"
                />
                <button className="block  md:inline-block top-0 right-0 md:absolute rounded-full bg-[#1976d2] px-8 py-4 text-white font-semibold tracking-wider">
                  {" "}
                  Suscribe
                </button>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center pt-5">
              <div className="flex mt-12 md:w-1/2">
                <div className="w-1/2">
                  <Box className="flex flex-row gap-2 content-center pb-8 ">
                    <img
                      src="/assets/logo.png"
                      alt="Logo"
                      className="w-4 h-4"
                    />
                    <Typography
                      color="#3D7EBE"
                      fontSize={"15px"}
                      fontWeight={700}
                    >
                      <Link to="/">ACE PACIFIC BANK</Link>
                    </Typography>
                  </Box>
                  <ul className="text-sm gap-2 flex flex-col list-disc ml-4 text-[#181818]">
                    <li>Contact Number: +49 62 917-30775</li>
                    <li>
                      <a href="mailto:Contact@acepacificdigital.com">
                        Contact@acepacificdigital.com
                      </a>
                    </li>
                    <li>Taunusanlage 18,60326 Frankfurt am main, Germany.</li>
                  </ul>
                </div>
                <div className="w-1/2">
                  <h2 className="font-bold text-lg mb-4 text-[#181818]">
                    Digital Banking
                  </h2>
                  <ul className="flex flex-col gap-2 text-sm text-[#181818]">
                    <li>Home</li>
                    <li>About</li>
                    <li>Blog</li>
                    <li>Contact</li>
                  </ul>
                </div>
              </div>
              <div className="flex mt-12 md:w-1/2">
                <div className="w-1/2">
                  <h2 className="font-bold text-lg mb-4 text-[#181818]">
                    User Menu
                  </h2>
                  <ul className="flex flex-col gap-2 text-sm text-[#181818]">
                    <li>Home</li>
                    <li>About</li>
                    <li>Blog</li>
                    <li>Contact</li>
                  </ul>
                </div>
                <div className="w-1/2">
                  <h2 className="font-bold text-lg mb-4 text-[#181818]">
                    Connect{" "}
                  </h2>
                  <div className="flex gap-4 items-center pb-4">
                    <span>
                      <CiFacebook size={20} color="blue" />
                    </span>
                    <span>
                      <CiInstagram size={20} color="#eb1e1e" />
                    </span>
                    <span>
                      <CiTwitter size={20} color="blue" />
                    </span>
                    <span>
                      <CiLinkedin size={20} color="red" />
                    </span>
                  </div>
                  <p className="text-[#7e7e7d] text-sm">
                    That’s business banking where people still matter, where
                    responsiveness counts, where local decision-making is a
                    priority.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#191919] text-white w-full  mt-12 py-6 justify-around text-center md:flex ">
            <p>©2023 Ace Pacific Bank. All rights reserved</p>
            <ul className="flex gap-6 text-xs md:text-sm items-center justify-center mt-3">
              <li>
                <a href="/">Privacy Policy</a>
              </li>
              <li>
                <a href="/">Cookie Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
