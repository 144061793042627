import { useState } from "react";
import { API_BASE_URL } from "../../constants/API_BASE_URL";
import { Box, Button, Modal } from "@mui/material";
import AuthCode from "react-auth-code-input";
import { LiaTimesSolid } from "react-icons/lia";

interface ModalProps {
    enable: boolean;
    setIsModalOpen: (value: boolean) => void;
    setSuccess: (value: boolean) => void;
    userId: string;
    handleSuccess?: () => void;
  }

  interface CreatePinProps extends ModalProps {
    handleSuccess?: () => void;
  }

  const CreatePin:React.FC<ModalProps> = ({
    enable,
  setIsModalOpen,
  setSuccess,
  userId,
  handleSuccess
  }) => {
    const [result, setResult] = useState<string>();
    const [message, setmessage] = useState<string>();
    const handleOnChange = async (res: any) => {
        setResult(res);
        setmessage("");
        console.log(res);
      };

      const handleSubmit = async() => {
        const response = await fetch(`${API_BASE_URL}/api/Wallet/CreatePin`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pin: result,
            userId: userId,
          }),
        });
        if (response.ok) {
            const data = await response.json();
            if (data.successful) {
              setIsModalOpen(false);
              setSuccess(true);
              if (handleSuccess) {
                handleSuccess();
              }
            }
        }else{
          setmessage("Failed to create PIN. Please try again.");
        }
      };
      
      return (
        <div className="confirmPin">
          <Modal
            open={enable}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="box_modal log rounded px-3">
          <div>
            <div
              className="modal_close flex items-center justify-center cursor-pointer"
              onClick={() => setIsModalOpen(!enable)}
            >
              <LiaTimesSolid size={25} />
            </div>
          </div>
          <h2 id="modal-modal-title" className="font-bold text-lg leading-3">
            Enter Pin
            </h2>
            <div className="pt-5 pb-2 flex justify-center gap-2 ">
            <AuthCode
              inputClassName="w-16 h-16 border mx-1 rounded bg-transparent
                          outline-none text-center font-semibold text-xl
                          spin-button-none border-gray-400
                           focus:text-gray-700 text-black
                          transition spin-button-none"
              onChange={handleOnChange}
              allowedCharacters="numeric"
              length={4}
            />
          </div>
          <Button
            variant="contained"
            size="medium"
            fullWidth
            sx={{
              mt: 2,
            }}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <span className="text-sm text-center block pt-3 text-red-600">
            {message}
          </span>
        </Box>
      </Modal>
    </div>
  );
};

export default CreatePin;