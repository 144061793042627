import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { LiaTimesSolid } from "react-icons/lia";
import Modal from "@mui/material/Modal";
import "./modal.css";
import { useAppSelector } from "../../Redux/hooks";
import { Link } from "react-router-dom";

interface ModalProps {
  enable: boolean; 
  setIsSubmitting: (value: boolean) => void;
  recipientWalletAccountNumber:string;
  withinBank:boolean;
}

const ConfirmAccountDetails: React.FC<ModalProps> = ({
  enable,
  setIsSubmitting,
  withinBank
}) => {
  const { accountNumber, accountName } = useAppSelector(
    ({ transfer }) => transfer
  );



  // const [open, setOpen] = useState<boolean>(false);
  // const handleOff = () => setOpen(false);
  // const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={enable}
        onClose={() => setIsSubmitting(!enable)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="box_modal">
          <div
            className="modal_close flex items-center justify-center cursor-pointer"
            onClick={() => setIsSubmitting(!enable)}
          >
            <LiaTimesSolid size={25} />
          </div>
          <h2 id="modal-modal-title" className="font-bold text-xl leading-3">
            Beneficiary Found
          </h2>
          <span className="text-xs text-[#7D8398]">Confirm Beneficiary </span>
          <div
            id="modal-modal-description"
            className="border border-[#E1E1E6] flex items-center gap-2 mt-3 px-2 py-1"
          >
            <div className="modal_img ">
              <img src="/assets/logo.png" alt="access" />
            </div>
            <div>
              <h3 className="text-black font-bold">{accountName} </h3>
              <p className="font-bold text-sm">
                Acepacefic Bank-{accountNumber}
              </p>
            </div>
          </div>
          <Button
            type="submit"
            variant="contained"
            size="medium"
            fullWidth
            sx={{
              mt: 2,
            }}
          >
            <Link
              to={
                withinBank
                  ? "/dashboard/transfer/acebank/amount"
                  : "/dashboard/transfer/slide-two"
              }
            >
              Confirm
            </Link>
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmAccountDetails;
