import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import TransferHeader from "../TransferHeader";
import { Box, Button } from "@mui/material";
import InputField from "../../FormInputField/InputField";
import TransboxHeader from "../TransboxHeader";
import { API_BASE_URL } from "../../../constants/API_BASE_URL";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import OtherBankConfirmPin from "../../Modals/OtherBankConfirmPin";


interface FormData {
  accountNumber: string;
  bankName: string;
  accountName: string;
  swiftCode: string;
  senderAccountNumber: string;
  routingNumber: string;
  transactionAmount: string;
  transactionNarration: string;
  senderAddress: string;
  postalCode: string;
}

interface BankList {
  bankName: string;
  bankCode: string;
  imageUrl: string;
}

const TransferOtherSlideOne = () => {
  const [isModaLOpen, setIsModalOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({
    accountNumber: "",
    bankName: "",
    accountName: "",
    senderAccountNumber: "",
    swiftCode: "",
    routingNumber: "",
    transactionAmount: "",
    transactionNarration: "",
    senderAddress: "",
    postalCode: ""
  });

  const [bankList, setBankList] = useState<BankList[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Partial<FormData>>({});

  //const isFormValid = Object.keys(validationErrors).length === 0;
  const isFormValid =
    Object.values(formData).every((value) => value.trim() !== '') &&
    Object.values(validationErrors).every((error) => !error);
  
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: name === 'accountName' ? validateAccountName(value) : undefined,
      [name]: name === 'accountNumber' ? validateAccountNumber(value) : undefined,
    }));
  };

  const { isLoading, withinBank } = useAppSelector(
    ({ transfer }) => transfer
  );

  const validateAccountNumber = (value: string): string | undefined => {
    if (!value.trim()) {
      return 'Account Number is required';
    }

    // Check if the value contains non-numeric characters
    if (!/^\d+$/.test(value)) {
      return 'Account Number should only contain numbers';
    }

    return undefined; // No validation error
  };

  const validateAccountName = (value: string): string | undefined => {
    if (!value.trim()) {
      return 'Account Name is required';
    }

    if (/\d/.test(value)) {
      return 'Account Name should not contain numbers';
    }

    if (/[^\w\s]/.test(value)) {
      return 'Account Name should not contain special characters including a full stop';
    }

    return undefined; // No validation error
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const errors: Partial<FormData> = {};

    if (Object.keys(errors).length === 0) {
    } else {
      setValidationErrors(errors);
      setIsSubmitting(false); // Set isSubmitting back to false if there are errors
    }
  };

  useEffect(() => {
    const fetchBankList = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/Bank/BankList`,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "69420",
            }),
          });
        if (response.ok) {
          const data = await response.json();

          if (Array.isArray(data.result)) {
            setBankList(data.result);
          } else {
            console.error('Unexpected data structure: ', data);
          }
        } else {
          console.error('Failed to fetch bank list');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      fetchBankList();
    }
  }, [loading]);

  return (
    <div className="">
      <TransferHeader />
      <div className="py-10  flex items-center justify-center ">
        <Box
          sx={{
            width: 420,
            background: "#ffffff",
          }}
          className="px-6 py-8 border border-[#DADCDF]"
        >
          <TransboxHeader />
          <div>
            <form onSubmit={handleSubmit}>
              <Box className="formControl flex flex-col gap-3 mt-5 mb-2">
                <InputField
                  type="text"
                  name="accountNumber"
                  label="Enter Account Number"
                  value={formData.accountNumber}
                  onChange={handleInputChange}
                  err={validationErrors.accountNumber}
                />

                <InputField
                  type="text"
                  name="accountName"
                  label="Enter Account Name"
                  value={formData.accountName}
                  onChange={handleInputChange}
                  err={validationErrors.accountName}
                />

                <InputField
                  type="text"
                  name="swiftCode"
                  label="Enter Swift Code"
                  value={formData.swiftCode}
                  onChange={handleInputChange}
                  err={validationErrors.swiftCode}
                />

                <InputField
                  type="text"
                  name="routingNumber"
                  label="Enter Routing Number"
                  value={formData.routingNumber}
                  onChange={handleInputChange}
                  err={validationErrors.routingNumber}
                />

                <InputField
                  type="text"
                  name="transactionAmount"
                  label="Enter Transaction Amount"
                  value={formData.transactionAmount}
                  onChange={handleInputChange}
                  err={validationErrors.transactionAmount}
                />

                <InputField
                  type="text"
                  name="transactionNarration"
                  label="Enter Transaction Narration"
                  value={formData.transactionNarration}
                  onChange={handleInputChange}
                  err={validationErrors.transactionNarration}
                />

                <InputField
                  type="text"
                  name="senderAddress"
                  label="Enter Sender Address"
                  value={formData.senderAddress}
                  onChange={handleInputChange}
                  err={validationErrors.senderAddress}
                />

                <InputField
                  type="text"
                  name="postalCode"
                  label="Enter Postal Code"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  err={validationErrors.postalCode}
                />

                {!loading && Array.isArray(bankList) && bankList.length > 0 && (
                  <div className="formControl flex flex-col gap-3 mt-5 mb-2">
                    <select
                      name="bankName"
                      value={formData.bankName}
                      onChange={handleSelectChange}
                    >
                      <option value="" disabled>Select Bank</option>
                      {bankList.map((bank) => (
                        <option key={bank.bankCode} value={bank.bankName}>
                          {bank.bankName}
                          {bank.imageUrl && (
                            <img
                              src={bank.imageUrl}
                              alt={bank.bankName}
                              style={{ marginLeft: '5px', maxHeight: '50px' }}
                            />
                          )}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {validationErrors && (
                  <span style={{ color: 'red' }}>{validationErrors.bankName}</span>
                )}
              </Box>
              {/* <Button
                type="button"
                variant="contained"
                size="medium"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => {
                  setIsModalOpen(!isModaLOpen);
                }}
                disabled={isSubmitting || Object.keys(validationErrors).length > 0} // Enable the disabled prop
              >
                Next
              </Button> */}
              <Button
                type="submit" // Change the button type to submit
                variant="contained"
                size="medium"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => {
                  setIsModalOpen(!isModaLOpen);
                }}
                disabled={isSubmitting || isFormValid} // Enable the disabled prop based on form validation
              >
                Next
              </Button>
            </form>
          </div>
        </Box>
        <OtherBankConfirmPin
          enable={isModaLOpen}
          setIsModalOpen={setIsModalOpen}
          setSuccess={setSuccess}
          transferDetails={{
            accountName: formData.accountName,
            accountNumber: formData.accountNumber,
            senderAccountNumber: formData.senderAccountNumber,
            bankName: formData.bankName,
            swiftCode: formData.swiftCode,
            routingNumber: formData.routingNumber,
            transactionAmount: formData.transactionAmount,
            transactionNarration: formData.transactionNarration,
            senderAddress: formData.senderAddress,
            postalCode: formData.postalCode,


          }}
        />
      </div>
    </div>
  );
};

export default TransferOtherSlideOne;
