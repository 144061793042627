import { Box, Button, FormControl, ModalProps } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { getUserName } from "../../Redux/transferSlice";
import ConfirmAccountDetails from "./ConfirmAccountDetails";
import TransferHeader from "../Transfer/TransferHeader";
import TransboxHeader from "../Transfer/TransboxHeader";
import { numberWithCommass } from "../../Helpers/NumberWithCommas";

interface FormData {
  recipientWalletAccountNumber: string;
}

const TransferModal = () => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [amountThousand, setAmountThousand] = useState("");

  const [isModaLOpen, setIsModalOpen] = useState<boolean>(false);

  const [validationErrors, setValidationErrors] = useState("");
  const [formData, setFormData] = useState<FormData>({
    recipientWalletAccountNumber: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // let formattedValue = value.replace(/,/g, "");
    // formattedValue = numberWithCommass(formattedValue);
    setFormData({ ...formData, [name]: value });
  };

  const { message, isLoading, success } = useAppSelector(
    ({ transfer }) => transfer
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData.recipientWalletAccountNumber.length < 10) {
      setValidationErrors("Please enter a valid account");
    }
    if (!validationErrors) {
      const recipientWalletAccountNumber = `${formData.recipientWalletAccountNumber}`;
      dispatch(getUserName({ accountNumber: recipientWalletAccountNumber }));
    }
    if (success) {
      setIsSubmitting(true);
    }
  };

  return (
    <div className="">
      <TransferHeader />
      <div className="py-10  flex items-center justify-center ">
        <Box
          sx={{
            width: 420,
            background: "#ffffff",
          }}
          className="px-6 py-8 border border-[#DADCDF]"
        >
          <TransboxHeader />
          <form onSubmit={handleSubmit}>
            <Box className="formControl flex flex-col gap-3 mt-5 mb-2">
              <FormControl>
                <label htmlFor={"accountNumber"} className="text-sm">
                  Account Number Here
                </label>
                <input
                  type="number"
                  name="recipientWalletAccountNumber"
                  value={formData.recipientWalletAccountNumber}
                  onChange={handleInputChange}
                  maxLength={10}
                  className={`text-[#3B4752] w-full focus:shadow-xl  spin-button-none border-[#cfd8e1] outline-0 border py-1.5 px-3 focus:border-[#3d7ebe] rounded ${
                    validationErrors ? "border-red-500" : ""
                  }`}
                />
                {validationErrors ? (
                  <div className="text-red-500 pt-1 text-sm">
                    {validationErrors}
                  </div>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              fullWidth
              sx={{
                mt: 2,
              }}
              disabled={isSubmitting}
            >
              {isLoading ? "Confirm details..." : "Next"}
            </Button>
          </form>
          <span className="text-sm text-center block pt-3 text-red-600">
            {message}
          </span>
          <ConfirmAccountDetails
            enable={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            recipientWalletAccountNumber={formData.recipientWalletAccountNumber}
            withinBank={true}
          />
        </Box>
      </div>
    </div>
  );
};
export default TransferModal;
