import React from 'react'

const PageNotFound = () => {
  return (
    <div
      style={{ height: "70vh", width: "100%" }}
      className="flex items-center justify-center "
    >
      <h1 className="text-[#242527] text-3xl"> Page not found...</h1>
    </div>
  )
}

export default PageNotFound