import { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { PiPaperPlaneTiltLight } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import { LiaTimesSolid } from "react-icons/lia";
import { TbUserHexagon } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { IoReceiptOutline } from "react-icons/io5";
import { LuSettings } from "react-icons/lu";
import { LuUserCircle2 } from "react-icons/lu";
import { BsPatchQuestion } from "react-icons/bs";
import { RiLogoutCircleRLine } from "react-icons/ri";

import { NavLink, Link, useLocation } from "react-router-dom";
import "./Navbar.css";

const NavbarMobile = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const prevPathname = useRef<string>("");
  const MenuItems = [
    {
      path: "/",
      name: "Home",
    },
    {
      path: "/dashboard/transfer",
      name: "About",
    },
    {
      path: "/dashboard/bill-payment",
      name: "Contact",
    },
    {
      path: "sign-up",
      name: "Sign up",
    },
  ];

  const [toggle, setToggle] = useState<boolean>(false);
  useEffect(() => {
    if (location.pathname !== prevPathname.current) {
      setToggle(true);
    }
  }, [location]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div className="tt z-40 block md:hidden fixed top-0 left-0 w-full bg-[#191919] h-10">
      <div
        className={`tdashmobilesidebar_container ${toggle ? "" : "tdisplay"} `}
      >
        <Box className="tdashmobilesidebar_burger" onClick={handleToggle}>
          <button
            onClick={handleToggle}
            className={`box ${toggle ? "open" : "close"}`}
          >
            <RxHamburgerMenu size={25} />
          </button>
          <button className={`times ${toggle ? "close" : "open"}`}>
            <LiaTimesSolid size={25} />
          </button>
        </Box>
        <div className="jj">
          <Box className="w-full flex flex-row gap-2 content-center pb-8">
            <img src="/assets/logo.png" alt="Logo" className="jj-img" />
            <p className="font-bold text-sm text-[#ffff] block">
              ACE PACIFIC BANK
            </p>
          </Box>
        </div>
        <div className="tdashmobilesidebar_menu">
          <Box className="flex flex-row gap-2 content-center pb-8  ">
            <img src="/assets/logo.png" alt="Logo" className="w-5" />
            <Typography color="#3D7EBE" fontSize={"13px"} fontWeight={700}>
              ACE PACIFIC BANK
            </Typography>
          </Box>
          <div className="sidebar_links">
            <div>
              <Box className="pb-10 h-full">
                <ul className="tj pt-10 flex flex-col justify-between gap-20 items-center font-bold px-4 h-full">
                  <li>
                    <NavLink
                      to={"/"}
                      className={({
                        isActive,
                      }) => `flex content-center justify-start gap-3  text-[#628ddd] 
                     `}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <Link
                      to="/about-us"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <NavLink
                      to={"/contact-us"}
                      className={({
                        isActive,
                      }) => `flex content-center justify-start gap-3  text-[#628ddd] 
                     `}
                    >
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/sign-up"}
                      className={({
                        isActive,
                      }) => `flex content-center justify-start gap-3  text-[#628ddd] 
                     `}
                    >
                      Sign-up
                    </NavLink>
                  </li>
                </ul>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
