import { useEffect } from "react";
import ComingSoon from "../../../components/ComingSoon";

const AirtimeAndData = () => {
  useEffect(() => {
    document.title = "Airtime & Data";
  });
  return (
    <div>
      <ComingSoon page="Airtime & Data" />
    </div>
  );
};

export default AirtimeAndData;
