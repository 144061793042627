import React, { useEffect, useState } from "react";
import { CgAddR, CgKey } from "react-icons/cg";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";
import { API_BASE_URL, formatDateObject } from "../../constants/API_BASE_URL";
import CreatePin from "../Modals/CreatePin";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import { BsBank } from "react-icons/bs";
import { MdOutlineSportsSoccer } from "react-icons/md";

interface Transaction {
  transactionType: number;
  transactionNarration: string;
  accountNumber: string;
  bankName: string;
  transactionAmount: string;
  dateCreated: string;
  status: string;
  recipientAccountName: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fefefe",
    color: "#121212",
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
    color: "#121212",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TransferHome = () => {
  const { user } = useAppSelector(({ auth }) => auth);
  const [dataPin, setDataPin] = useState(null);
  const [hasPin, setHasPin] = useState(false);
  const [isCreatePinModalOpen, setIsCreatePinModalOpen] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);

  const handleCreatePinClick = () => {
    setIsCreatePinModalOpen(true);
  };
  const handleCreatePinSuccess = () => {
    setIsCreatePinModalOpen(false);
    setSuccess(true);
    window.location.reload();
  };

  useEffect(() => {
    const fetchPinStatus = async () => {
      setIsLoading(true);
      try {
        const endPoint = `${API_BASE_URL}/api/Wallet/GetUserAccountAdmin/${user?.accountNumber}`;
        const options = {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "69420",
          }),
        };
        const response = await fetch(endPoint, options);
        if (response.ok) {
          const data = await response.json();
          setDataPin(data.result.pin);
          console.log(data)
        } else {
          console.error("Failed to fetch pin status");
        }
      } catch (error) {
        console.error("Error fetching pin status:", (error as Error).message);
      }
    };
    const fetchUserTransactions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/Wallet/ViewUserTransactionHistory?userId=${user?.userId}`,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "69420",
            }),
          });
        if (response.ok) {
          const data = await response.json();
          setTransactionHistory(data.result);
          console.log(data);
        } else {
          console.error("Failed to fetch user transactions");
        }
      } catch (error) {
        console.error("Error fetching user transactions:", (error as Error).message);
      }
    }
    if (user) {
      fetchPinStatus();
      fetchUserTransactions();
    }
  }, [user]);


  return (
    <div>
      <div className="flex flex-col gap-6 md:flex-row justify-start md:justify-between items-start md:items-center">
        <div>
          <h1 className="text-2xl font-bold tracking-wider">Transfer</h1>
          <p className="text-[#7D8398] text-sm">
            Enjoy instant & fast transfer with Ace Pacific Bank{" "}
          </p>
        </div>

        {dataPin === null ? (<Link
          to={""}
          onClick={handleCreatePinClick}
          className="flex items-center gap-2 rounded-md text-sm bg-[#4318FF] text-white px-2 py-2 content-end self-end"
        >
          <CgKey size={17} />
          Create Pin
        </Link>) : (
          <p>Reset Pin</p>
        )}

        {isCreatePinModalOpen && (
          <CreatePin
            enable={true}
            setIsModalOpen={setIsCreatePinModalOpen}
            handleSuccess={handleCreatePinSuccess}
            setSuccess={setSuccess}
            userId={user?.id || ''}
          />
        )}

        <Link
          to={"/dashboard/transfer/opt"}
          className="flex items-center gap-2 rounded-md text-sm bg-[#4318FF] text-white px-2 py-2 content-end self-end"
        >
          <CgAddR size={17} />
          New Instant Transfer
        </Link>
      </div>
      <div className="h-5/6 flex items-center justify-center pt-10">
        {transactionHistory.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell align="left">Details </StyledTableCell>
                  <StyledTableCell align="left">
                    Account No.
                  </StyledTableCell>
                  <StyledTableCell align="left">Amount</StyledTableCell>
                  <StyledTableCell align="left">Date</StyledTableCell>

                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistory.map((transaction: Transaction, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      <div className="font-bold bg-red-200 py-2 rounded-lg flex justify-center items-center">
                        {transaction.transactionType === 0 ? <BsBank size={40} /> : ""}
                        {transaction.transactionType === 1 ? (
                          <MdOutlineSportsSoccer size={18} />
                        ) : (
                          ""
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className="font-bold ">{transaction.transactionNarration}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className="font-normal text-[#7D8398]">
                        {transaction.recipientAccountName}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className="font-normal text-[#7D8398]">
                        ${transaction.transactionAmount}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className=" font-normal text-[#7D8398] ">{formatDateObject(transaction.dateCreated)}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className="font-normal bg-green-100 px-3 py-1 rounded-full">
                        {transaction.status}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p className="text-lg md:text-2xl text-[#7D8398] text-center">
            You have not made any transfers in the last 60 days.
          </p>
        )}
      </div>
    </div>
  );
};

export default TransferHome;
