import "./App.css";
import { Routes, Route } from "react-router-dom";
import SignIn from "./Pages/SignIn/SignIn";
import SignUp from "./Pages/SignUp/SignUp";
import SignupInfo from "./Pages/SignUp/SignupInfo/SignupInfo";
import SignupConfirmPassword from "./Pages/SignUp/SignupConfirmPassword/SignupConfirmPassword";
import Dashboard from "./Pages/Dashboard/Dashboard";
import DashboardHome from "./Pages/Dashboard/DashboardHome";
import Transfer from "./Pages/Dashboard/Transfer/Transfer";
import BillPayments from "./Pages/Dashboard/BillPayments/BillPayments";
import AirtimeAndData from "./Pages/Dashboard/AirtimeAndData/AirtimeAndData";
import Transactions from "./Pages/Dashboard/Transactions/Transactions";
import Profile from "./Pages/Dashboard/Profile/Profile";
import Settings from "./Pages/Dashboard/Settings/Settings";
import LandingPage from "./Layout/LandingPage/LandingPage";
import TransferHome from "./components/Transfer/TransferHome";
import TransferFirstInfo from "./components/Transfer/TrasnferOtherBank/TransferOtherSlideOne";
import TransferTwoInfo from "./components/Modals/OtherBankConfirmPin";
import TransferThreeInfo from "./components/Transfer/TransferConfirmDetails";
import TransferOptions from "./components/Transfer/TransferOptions";
import TransferAceSlideOne from "./components/Transfer/TransferAceBank/TransferAceSlideOne";
import Notification from "./Pages/Dashboard/Notification/Notification";
import PageNotFound from "./Layout/PageNotFound";
import Admin from "./Pages/Dashboard/Admin/Admin";
import TransferAceAmount from "./components/Transfer/TransferAceBank/TransferAceAmount";
import CreatePin from "./components/Modals/CreatePin";
import TransferFinal from "./components/Transfer/TransferFinal";
import TransferModal from "./components/Modals/TransferModal";
import SuccessfulTransfer from "./components/Modals/SuccessfulTransfer";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route element={<SignUp />}>
          <Route path="/sign-up" element={<SignupInfo />} />
          <Route path="/sign-up/set-password" element={<SignupConfirmPassword />} />
        </Route>

        <Route
  path="/Modals/SuccessfulTransfer"element={ <SuccessfulTransfer/>  }/>


        <Route element={<Dashboard />}>
          <Route path="/dashboard" element={<DashboardHome />} />
          <Route element={<Transfer />}>
            <Route path="/dashboard/transfer" element={<TransferHome />} />
            <Route path="/dashboard/transfer/opt" element={<TransferOptions />} />
            <Route path="/dashboard/transfer/acebank" element={<TransferModal />} />
            <Route path="/dashboard/transfer/acebank/amount" element={<TransferAceAmount />} />
            <Route path="/dashboard/transfer/slide-one" element={<TransferFirstInfo />} />
            <Route path="/dashboard/transfer/slide-three" element={<TransferThreeInfo />} />
            <Route path="/dashboard/transfer/transferFinal" element={<TransferFinal />} />
          </Route>
          <Route path="/dashboard/admin" element={<Admin />} />
          <Route path="/dashboard/bill-payment" element={<BillPayments />} />
          <Route path="/dashboard/airtime-data" element={<AirtimeAndData />} />
          <Route path="/dashboard/transactions" element={<Transactions />} />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route path="/dashboard/notification" element={<Notification />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
