import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import InputFieldPassword from "../../../components/FormInputField/InputFieldPassword";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { cleanUp } from "../../../Redux/authSlice";
import { signUpUser } from "../../../Redux/authSlice";
import { useNavigate } from "react-router-dom";

interface FormData {
  password: string;
  confirmPassword: string;
}
interface User {
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
}

const SignupConfirmPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, loading, message } = useAppSelector(({ auth }) => auth);

  const [formData, setFormData] = useState<FormData>({
    confirmPassword: "",
    password: "",
  });

  const loadUserFromLocalStorage = (): User => {
    const user = localStorage.getItem("info");
    return user ? JSON.parse(user) : null;
  };

  useEffect(() => {
    const info = loadUserFromLocalStorage();
    document.title = "Sign in";
    if (isLoggedIn) {
      navigate("/dashboard");
    }
    if (!info) {
      navigate("/sign-up");
    }
  });
  const [passwordsMatchError, setPasswordsMatchError] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<Partial<FormData>>(
    {}
  );

  const validateField = (fieldName: keyof FormData): string => {
    return formData[fieldName].trim()
      ? ""
      : `${fieldName} fields cannot be empty`;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as { name: keyof FormData; value: string };
    setFormData({ ...formData, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name),
    }));
  };

  const validatePassword = () => {
    if (formData.password !== formData.confirmPassword) {
      setPasswordsMatchError("Passwords do not match");
    } else {
      setPasswordsMatchError("");
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fields: (keyof FormData)[] = ["confirmPassword", "password"];
    const errors: Partial<FormData> = {};

    fields.forEach((field) => {
      errors[field] = validateField(field);
    });

    setValidationErrors(errors);
    validatePassword();

    if (!validationErrors.password && !validationErrors.confirmPassword) {
      const info = loadUserFromLocalStorage();
      const signUpData = {
        ...info,
        ...formData,
      };
     
      dispatch(signUpUser(signUpData));
      if (isLoggedIn) {
        localStorage.removeItem("info");
        dispatch(cleanUp())
        navigate("/dashboard");
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box className="formControl flex flex-col gap-2 mt-5 mb-2">
          <InputFieldPassword
            type="password"
            name="password"
            label="Password"
            value={formData.password}
            onChange={handleInputChange}
            err={validationErrors.password}
          />
          <InputFieldPassword
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            err={validationErrors.confirmPassword}
          />
          {passwordsMatchError && (
            <Typography variant="body2" color="error">
              {passwordsMatchError}
            </Typography>
          )}
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          sx={{ mt: 1, fontSize: "13px" }}
          disabled={loading}
        >
          {loading ? "loading..." : "Register"}
        </Button>

        <span className="text-sm text-center block pt-3 text-red-600">
          {message}
        </span>
      </form>
    </div>
  );
};

export default SignupConfirmPassword;
