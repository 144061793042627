import React, { useState } from "react";
import TransferHeader from "./TransferHeader";
import { Box, Button } from "@mui/material";
import TransboxHeader from "./TransboxHeader";
import ConfirmPin from "../Modals/ConfirmPin";
import useTransferSelector from "./useTransferSelector";
import { numberWithCommas } from "../../Helpers/NumberWithCommas";

const TransferConfirmDetails = () => {
  const [isModaLOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    recipientWalletAccountNumber,
    amount,
    accountNumber,
    accountName,
    withinBank,
    transactionNarration,
  } = useTransferSelector();

  const [success, setSuccess] = useState<boolean>(false);
  return (
    <div>
      <TransferHeader />
      <div className="py-10  flex items-center justify-center ">
        <Box
          sx={{
            width: 420,
            background: "#ffffff",
          }}
          className="px-6 py-8 border border-[#DADCDF]"
        >
          <TransboxHeader />
          <div>
            <div className="text-center pt-3">
              <h2 className="font-bold text-sm">Transfer Amount</h2>
              <p className="text-[#3D7EBE] font-bold text-4xl">
                ${numberWithCommas(amount)}.00
              </p>
            </div>
            <div className="pt-10">
              <h3 className="text-xs font-bold pb-3">Transfer details</h3>
              <div className="flex items-center justify-between">
                <h4 className="text-sm text-[#323238]">Amount</h4>
                <span className="text-sm font-bold">
                  ${numberWithCommas(amount)}.00
                </span>
              </div>
              {/* <div className="flex items-center justify-between pt-2">
                <h4 className="text-sm text-[#323238]">Fees</h4>
                <span className="text-sm font-bold">{computeCharge(amount)}</span>
              </div> */}
              <div className="flex items-center justify-between pt-2">
                <h4 className="text-sm text-[#323238]">
                  Transaction Narration
                </h4>
                <span className="text-sm font-bold">
                  {transactionNarration}
                </span>
              </div>
              <div className="flex items-center justify-between pt-5">
                <h4 className="text-sm text-[#323238]">Total Debit</h4>
                <span className="text-sm font-bold">
                  {numberWithCommas(amount)}
                </span>
              </div>

              <div
                id="modal-modal-description"
                className="border border-[#E1E1E6] flex items-center gap-2 mt-6 px-2 py-1"
              >
                <div className="modal_img ">
                  <img src="/assets/logo.png" alt="access" />
                </div>
                <div>
                  <h3 className="text-black font-bold">{accountName} </h3>
                  <p className="font-bold text-sm">
                    Acepacific Bank- {accountNumber}
                  </p>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              fullWidth
              sx={{
                mt: 4,
              }}
              onClick={() => {
                console.log(
                  "recipientWalletAccountNumber in TransferConfirmDetails:",
                  accountNumber
                );
                setIsModalOpen(!isModaLOpen);
                console.log(
                  "recipientWalletAccountNumber in TransferConfirmDetails:",
                  recipientWalletAccountNumber
                );
              }}
            >
              Proceed
            </Button>
          </div>
        </Box>

        <ConfirmPin
          enable={isModaLOpen}
          setIsModalOpen={setIsModalOpen}
          setSuccess={setSuccess}
          userId=""
          recipientWalletAccountNumber={recipientWalletAccountNumber}
          transferDetails={{
            amount,
            accountNumber,
            accountName,
            withinBank,
            transactionNarration,
            recipientWalletAccountNumber,
          }}
        />
      </div>
    </div>
  );
};

export default TransferConfirmDetails;
