import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { IoAlertCircleOutline, IoThumbsUp, IoThumbsUpSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const SuccessfulTransferPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Redirect to the homepage after 3 seconds
      navigate("/Dashboard");
    }, 6000);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        borderRadius: "8px",
        padding: "16px",
        textAlign: "center",
      }}
    >
      <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <IoThumbsUp size={200} color="green" />
    </div>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Transfer Pending Approval!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        You will be redirected to the homepage shortly.
      </Typography>
    </Box>
  );
};

export default SuccessfulTransferPage;
