import { useEffect } from "react"
import ComingSoon from "../../../components/ComingSoon";

const Settings = () => {
  useEffect(() => {
    document.title = "Settings";
  });
  return (
    <div>
      <ComingSoon page="Settings"/>
    </div>
  )
}

export default Settings